import { I18nModule } from '@agdir/i18n/angular';
import { Classifier, ClassifierService, WellKnownClassifierId } from '@agdir/classifiers';

import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { AddClassifierModalComponent } from '../../../../classifiers/src/lib/components/add-classifier-modal.component';
import { ClassifierSelectComponent } from './classifier-select.component';
import { derivedAsync } from 'ngxtension/derived-async';

@Component({
	standalone: true,
	selector: 'agdir-crop-select',
	template: `
		<!--// TODO; Replace agdir-crop-select usages with agdir-classifier-select -->
		<agdir-classifier-select
			[classifier]="selectedValue()"
			[disabled]="disabled()"
			[size]="size()"
			(classifierChange)="picked.emit($event)"
			[knownClassifierId]="WellKnownClassifierId.CropTypes"
			[showAddNew]="true"
		/>
	`,

	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NzSpinComponent, FormsModule, NzIconModule, I18nModule, AddClassifierModalComponent, ClassifierSelectComponent],
})
export class CropTypeSelectComponent {
	picked = output<Classifier>();
	clear = output<void>();
	size = input<'default' | 'large' | 'small'>('default');
	value = input.required<string | undefined | null>();
	disabled = input(false);

	private readonly classifierService = inject(ClassifierService);

	selectedValue = derivedAsync(async () => {
		const v = this.value();
		return v ? await this.classifierService.getClassifierByCodeAsync(v) : null;
	});

	protected readonly WellKnownClassifierId = WellKnownClassifierId;
}
