import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, model, signal } from '@angular/core';
import { ButtonComponent } from '@agdir/ui/button';
import { CUSTOMER_PATHS, SharesService } from '@agdir/services';
import { AgdirCardComponent, ResponsiveService } from '@agdir/core/angular';
import { Invitation, InvitationService, InvitationStatus } from '@agdir/heimdall';
import { ViewBaseComponent } from '../auth/components/view-base.component';
import { derivedAsync } from 'ngxtension/derived-async';
import { firstValueFrom } from 'rxjs';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { AgdirBottomSheetComponent } from '@agdir/layout';

@Component({
	standalone: true,
	selector: 'agdir-customer-invitations-list',
	template: `
		<agdir-card title="invitations.title">
			<div menu class="flex-row-end gap-1">
				<span transloco="invitations.showAllToggle"></span>
				<nz-switch [(ngModel)]="seeAll"></nz-switch>
			</div>
			@if (invitations().length) {
				<div subTitle transloco="invitations.youHaveCountPending" [translocoParams]="{ invitationCount: invitations().length }"></div>
			}
			<table class="agdir-table" [class.hidden]="!invitations().length">
				<thead>
					<tr>
						<th transloco="invitations.status.invitedBy"></th>
						<th transloco="invitations.status.invitedTo"></th>
						<th transloco="invitations.status.invitedAt"></th>
						<th class="text-right text-nowrap" transloco="invitations.status.status"></th>
					</tr>
				</thead>
				<tbody>
					@for (i of invitations(); track $index) {
						<tr>
							<td>
								<span>{{ i.inviter?.name }}</span>
							</td>
							<td>
								{{ i.company?.organizationName }}
							</td>
							<td>
								<time [innerText]="i.createdAt | date: 'shortDate'"></time>
							</td>
							<td class="text-right ">
								<div class="flex-row-end">
									@if (i.status === 'pending' && !isMobile()) {
										<agdir-button
											(click)="changeStatus(i, InvitationStatus.DECLINED)"
											[disabled]="isDeleting().has(i) || isApproving().has(i)"
											[isProcessing]="isDeleting().has(i)"
											[attr.data-cy]="'declineInvite' + i?.company?._id"
											[size]="isMobile() ? 'small' : 'default'"
											[label]="isTablet() ? '' : 'invitations.status.declineInvite'"
											color="red-ghost"
											icon="close"
										/>
										<agdir-button
											(click)="changeStatus(i, InvitationStatus.ACCEPTED)"
											[disabled]="isDeleting().has(i) || isApproving().has(i)"
											[isProcessing]="isApproving().has(i)"
											[attr.data-cy]="'acceptInvite' + i?.company?._id"
											[size]="isMobile() ? 'small' : 'default'"
											color="primary"
											icon="check"
											[label]="isTablet() ? '' : 'invitations.status.acceptInvite'"
										/>
									} @else if (i.status === 'pending' && isMobile()) {
										<!--											(click)="changeStatus(i, InvitationStatus.ACCEPTED)"-->
										<agdir-button
											(click)="openSheet.set(i)"
											[attr.data-cy]="'openInvite' + i?.company?._id"
											size="small"
											color="secondary"
											icon="email"
											label="invitations.status.openStatus"
										/>
									} @else {
										<div class="capitalize">
											{{ 'invitations.status.' + i.status | transloco }} {{ i.changedAt | date: 'shortDate' }}
										</div>
										@if (i.status === InvitationStatus.ACCEPTED && showGoToFarm()) {
											<agdir-button
												[size]="isMobile() ? 'small' : 'default'"
												label="invitations.goToFarm"
												(click)="goToFarm(i)"
											/>
										}
									}
								</div>
							</td>
						</tr>
					}
				</tbody>
			</table>
		</agdir-card>
		<agdir-bottom-sheet [isVisible]="!!openSheet()" [closeButton]="false" title="invitations.mobileDrawer.title">
			<div class="flex-col-start w-full flex-1 gap-2" style="font-size:16px">
				<div transloco="invitations.status.invitedBy"></div>
				<span class="font-bold">{{ openSheet()?.inviter?.name }}</span>
			</div>
			<div class="flex-col-start w-full flex-1 gap-2" style="font-size:16px">
				<div transloco="invitations.status.invitedTo"></div>
				<span class="font-bold">{{ openSheet()?.company?.organizationName }}</span>
			</div>
			<div class="flex-col-start w-full flex-1 gap-2" style="font-size:16px">
				<div transloco="invitations.status.invitedBy"></div>
				<time class="font-bold" [innerText]="openSheet()?.createdAt | date: 'shortDate'"></time>
			</div>
			<div class="flex-row-between w-full">
				<agdir-button
					class="text-[16px]"
					(click)="changeStatus(openSheet(), InvitationStatus.DECLINED)"
					[disabled]="isDeleting().has(openSheet()) || isApproving().has(openSheet())"
					[isProcessing]="isDeleting().has(openSheet())"
					[attr.data-cy]="'declineInvite' + openSheet()?.company?._id"
					label="invitations.status.declineInvite"
					color="red-ghost"
					icon="close"
				/>
				<agdir-button
					class="text-[16px]"
					(click)="changeStatus(openSheet(), InvitationStatus.ACCEPTED)"
					[disabled]="isDeleting().has(openSheet()) || isApproving().has(openSheet())"
					[isProcessing]="isApproving().has(openSheet())"
					[attr.data-cy]="'acceptInvite' + openSheet()?.company?._id"
					color="primary"
					icon="check"
					label="invitations.status.acceptInvite"
				/>
			</div>
			<div class="flex-row-center w-full">
				<agdir-button class="text-[16px]" label="general.closeText" (click)="openSheet.set(null)" color="ghost" />
			</div>
		</agdir-bottom-sheet>
	`,
	host: { class: 'flex flex-col gap-1 md:gap-2' },
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		AgdirCardComponent,
		TranslocoDirective,
		NzSwitchComponent,
		FormsModule,
		ButtonComponent,
		DatePipe,
		TranslocoPipe,
		AgdirBottomSheetComponent,
	],
})
export class CustomerInvitationsListComponent extends ViewBaseComponent {
	seeAll = model(false);
	showGoToFarm = input(false);
	openSheet = signal<Invitation | null>(null);

	hasAcceptedAtLeastOne = false;
	isApproving = signal(new Set<Invitation>());
	isDeleting = signal(new Set<Invitation>());
	localInvitations = signal<Invitation[] | null>(null);
	responsiveService = inject(ResponsiveService);
	isMobile = this.responsiveService.isMobile();
	isTablet = this.responsiveService.isTablet(true);
	sharesService = inject(SharesService);
	protected readonly CUSTOMER_PATHS = CUSTOMER_PATHS;
	protected readonly history = history;
	protected readonly InvitationStatus = InvitationStatus;
	private readonly invitationService = inject(InvitationService);
	invitations = derivedAsync(
		async () => {
			const seeAll = this.seeAll();
			const localInvitations = this.localInvitations();
			const i = localInvitations || (await firstValueFrom(this.invitationService.getForCurrentCustomer()));
			return i.filter((i) => seeAll || i.status === InvitationStatus.PENDING);
		},
		{ initialValue: [] },
	);

	override async submitStep(event?: Event): Promise<boolean> {
		window.location.href = CUSTOMER_PATHS.ALL_FARMS;
		return true;
	}

	async changeStatus(i: Invitation, newStatus: InvitationStatus) {
		switch (newStatus) {
			case InvitationStatus.ACCEPTED:
				this.isApproving.update((s) => s.add(i));
				await this.invitationService.accept(i);
				break;
			case InvitationStatus.DECLINED:
				this.isDeleting.update((s) => s.add(i));
				await this.invitationService.decline(i);
				break;
			case InvitationStatus.DELETED:
				this.isDeleting.update((s) => s.add(i));
				await this.invitationService.delete(i);
				break;
		}
		await firstValueFrom(this.sharesService.getMyCompanies(true));
		await this.auth.refreshToken();
		this.isApproving.update((s) => {
			s.delete(i);
			return s;
		});
		this.isDeleting.update((s) => {
			s.delete(i);
			return s;
		});
		i.status = newStatus;
		i.changedAt = new Date();
		const newInvitationsSet = [i, ...this.invitations().filter((inv) => inv.code !== i.code)];
		this.localInvitations.set(newInvitationsSet);
		this.openSheet.set(null);
	}

	goToFarm(i: Invitation) {
		window.location.href = `/${i.company?._id}`;
	}
}
