import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-plain-text',
	standalone: true,
	template: `
		{{ formControl.value }}
	`,
})
export class PlainTextComponent extends FieldType<FieldTypeConfig> {}
