import { I18nService } from '@agdir/i18n/angular';
import { MapsModule } from '@agdir/maps';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import enGB from 'date-fns/locale/en-GB';
import nb from 'date-fns/locale/nb';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { AutosizeModule } from 'ngx-autosize';
import { DatepickerComponent } from './components/datepicker.component';
import { AddActiveIngredientsComponent } from './formly/active-ingredients.component';
import { AgdirDatepickerComponent } from './formly/agdir-datepicker.component';
import { AgdirFormlyButtonComponent } from './formly/agdir-formly-button.component';
import { AgdirFormlyClassifierSelect } from './formly/agdir-formly-classifier-select.component';
import { AgdirFormlyTextareaComponent } from './formly/agdir-formly-textarea.component';
import { AgdirWeatherConditionComponent } from './formly/agdir-weather-condition.component';
import { AgdirWeekdaySelectComponent } from './formly/agdir-weekday-select.component';
import { AgdirWindConditionComponent } from './formly/agdir-wind-condition.component';
import { FormlyButtonToggleComponent } from './formly/button-toggle.component';
import { CompanyPeopleFieldTypeComponent } from './formly/company-people-selector.component';
import { CropTypeComponent } from './formly/crop-type.component';
import { DevicesFieldTypeComponent } from './formly/devices.component';
import { DividerComponent } from './formly/divider.component';
import { ExpandablePanelComponent } from './formly/expandable-panel.component';
import { TranslateExtension } from './formly/extensions';
import { FormlyAttachmentsComponent } from './formly/file-attachment.component';
import { FormlyImageComponent } from './formly/formly-image.component';
import { GeoLocationFieldTypeComponent } from './formly/geo-location.component';
import { HtmlComponent } from './formly/html.component';
import { LocationFieldTypeComponent } from './location-selector/location.component';
import { LocationsFieldTypeComponent } from './location-selector/locations.component';
import { PhoneNumberFormlyComponent } from './formly/phone-number.component';
import { PlainTextComponent } from './formly/plain-text.component';
import { ReasonEntryComponent } from './formly/reason-entry.component';
import { SimpleSelectComponent } from './formly/simple-select.component';
import { VerticalStepperComponent } from './formly/vertical-stepper.component';

export enum FormlyCustomWrapper {
	ExpandablePanel = 'expandable-panel',
}

export enum FormlyCustomType {
	Divider = 'divider',
	Html = 'html',
	Stepper = 'stepper',
	NakedTextArea = 'agdir-naked-textarea',
	PlainText = 'agdir-plain-text',
	CompanyPeople = 'agdir-company-people',
	PhoneNumber = 'agdir-phone-number',
	Location = 'agdir-formly-location',
	Locations = 'agdir-locations',
	Devices = 'agdir-devices',
	Datepicker = 'agdir-datepicker',
	Attachments = 'agdir-attachments',
	Image = 'agdir-image',
	Weather = 'agdir-weather-conditions',
	Wind = 'agdir-wind-conditions',
	CropType = 'agdir-crop-type',
	Button = 'agdir-formly-field-button',
	ReasonEntry = 'agdir-reason-entry',
	ActiveIngredientsList = 'agdir-add-active-ingredients',
	ButtonToggle = 'agdir-button-toggle',
	SimpleSelect = 'agdir-simple-select',
	GeoLocation = 'agdir-geo-location',
	Textarea = 'agdir-formly-textarea',
	WeekdaySelect = 'agdir-weekday-select',
	ClassifierSelect = 'agdir-classifier-select',
}

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AutosizeModule,
		MatSelectModule,
		MapsModule,
		FormlyModule.forRoot({
			wrappers: [{ name: FormlyCustomWrapper.ExpandablePanel, component: ExpandablePanelComponent }],
			validators: [{ name: 'email', validation: EmailValidator }],
			types: [
				{ name: FormlyCustomType.Stepper, component: VerticalStepperComponent, wrappers: [] },
				{ name: FormlyCustomType.Divider, component: DividerComponent },
				{ name: FormlyCustomType.Html, component: HtmlComponent },
				{ name: FormlyCustomType.PlainText, component: PlainTextComponent },
				{ name: FormlyCustomType.CompanyPeople, component: CompanyPeopleFieldTypeComponent },
				{ name: FormlyCustomType.PhoneNumber, component: PhoneNumberFormlyComponent },
				{ name: FormlyCustomType.Locations, component: LocationsFieldTypeComponent },
				{ name: FormlyCustomType.Location, component: LocationFieldTypeComponent },
				{ name: FormlyCustomType.Datepicker, component: AgdirDatepickerComponent },
				{ name: FormlyCustomType.Attachments, component: FormlyAttachmentsComponent },
				{ name: FormlyCustomType.Image, component: FormlyImageComponent },
				{ name: FormlyCustomType.Weather, component: AgdirWeatherConditionComponent },
				{ name: FormlyCustomType.Wind, component: AgdirWindConditionComponent },
				{ name: FormlyCustomType.CropType, component: CropTypeComponent },
				{ name: FormlyCustomType.Button, component: AgdirFormlyButtonComponent },
				{ name: FormlyCustomType.ReasonEntry, component: ReasonEntryComponent },
				{ name: FormlyCustomType.ActiveIngredientsList, component: AddActiveIngredientsComponent },
				{ name: FormlyCustomType.ButtonToggle, component: FormlyButtonToggleComponent },
				{ name: FormlyCustomType.SimpleSelect, component: SimpleSelectComponent },
				{ name: FormlyCustomType.GeoLocation, component: GeoLocationFieldTypeComponent },
				{ name: FormlyCustomType.Devices, component: DevicesFieldTypeComponent },
				{ name: FormlyCustomType.Textarea, component: AgdirFormlyTextareaComponent },
				{ name: FormlyCustomType.WeekdaySelect, component: AgdirWeekdaySelectComponent },
				{
					name: FormlyCustomType.ClassifierSelect,
					component: AgdirFormlyClassifierSelect,
				},
			],
		}),
		//FormlyMaterialModule,
		// DateFnsModule,
		DatepickerComponent,
		//FormlyMatDatepickerModule,
		FormlyNgZorroAntdModule,
	],
	declarations: [],
	providers: [
		{ provide: FORMLY_CONFIG, multi: true, useFactory: registerFormlyExtensions, deps: [I18nService] },
		{ provide: MAT_DATE_LOCALE, useFactory: matLocaleFactory, deps: [I18nService] },
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'dd.MM.yyyy',
				},
				display: {
					dateInput: 'dd.MM.yyyy',
					monthYearLabel: 'LLL uuuu',
					dateA11yLabel: 'PP',
					monthYearA11yLabel: 'LLLL uuuu',
				},
			},
		},
	],
	exports: [
		FormlyModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatButtonModule,
		MatCheckboxModule,
		MatSelectModule,
		MatInputModule,
		DatepickerComponent,
		NzFormModule,
		NzDatePickerModule,
	],
})
export class AgdirFormsModule {}

function matLocaleFactory(translate: I18nService) {
	if (translate.getActiveLang()?.toLocaleLowerCase() === 'no') {
		return nb;
	}
	return enGB;
}

function registerFormlyExtensions(translate: I18nService) {
	return {
		extensions: [
			{
				name: 'translate',
				extension: new TranslateExtension(translate),
			},
		],
	};
}

export function EmailValidator(control: AbstractControl): ValidationErrors | null {
	const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
	return emailPattern.test(control.value) ? null : { email: true };
}
