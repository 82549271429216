import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { WindEntryComponent } from '../components/wind-entry.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-wind-condition',
	template: `
		<agdir-wind-entry [control]="formControl"></agdir-wind-entry>
	`,
	imports: [WindEntryComponent],
})
export class AgdirWindConditionComponent extends FieldType<FieldTypeConfig> {}
