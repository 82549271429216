<button
	type="button"
	matRipple
	(click)="drawBtnClick()"
	class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5"
	[class.text-purple-500]="drawEnabled"
	[class.cursor-crosshair]="drawEnabled">
	<agdir-icon class="w-3" icon="edit" />
	<span>Draw location</span>
</button>
