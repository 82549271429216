import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindMeComponent } from './find-me.component';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@NgModule({
	declarations: [FindMeComponent],
	exports: [FindMeComponent],
	imports: [CommonModule, AgdirIconComponent],
	providers: [{ provide: Window, useValue: window }],
})
export class FindMeModule {}
