import { ChangeDetectionStrategy, Component, computed, effect, inject, signal, Signal } from '@angular/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';
import { AgdirSelectComponent, PhoneNumberComponent } from '@agdir/agdir-forms';
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzFormControlComponent, NzFormDirective, NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { AccessLevelToStringPipe, Invitation, InvitationService } from '@agdir/heimdall';
import { CompanyAccessLevel } from '@agdir/domain';
import { toSignal } from '@angular/core/rxjs-interop';
import { AgdirCardComponent } from '@agdir/core/angular';
import { firstValueFrom } from 'rxjs';
import { CompanyService } from '@agdir/services';
import { ButtonComponent } from '@agdir/ui/button';
import { AgdirAlertComponent, AgdirSnackbarService } from '@agdir/alert';

interface UserForm {
	permission: FormControl<CompanyAccessLevel>;
	phoneNumber: FormControl<string>;
	name: FormControl<string>;
	surname: FormControl<string>;
	email: FormControl<string>;
	sendSms: FormControl<boolean>;
	sendEmail: FormControl<boolean>;
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-invite-customer-card',
	template: `
		<agdir-card title="invitations.toCompany.form.title">
			<form [formGroup]="userForm" nz-form nzLayout="vertical">
				<div class="grid grid-cols-1 lg:grid-cols-2 gap-x-2">
					<nz-form-item>
						<nz-form-label>{{ 'invitations.toCompany.form.name' | transloco }}</nz-form-label>
						<nz-form-control>
							<input nz-input formControlName="name" class="ant-input" autocomplete="given-name" />
						</nz-form-control>
					</nz-form-item>
					<nz-form-item>
						<nz-form-label>{{ 'invitations.toCompany.form.surname' | transloco }}</nz-form-label>
						<nz-form-control>
							<input nz-input formControlName="surname" class="ant-input" autocomplete="family-name" />
						</nz-form-control>
					</nz-form-item>
					<nz-form-item>
						<nz-form-label>
							{{ 'invitations.toCompany.form.email' | transloco }}
							@if (userForm.get('email')!.errors?.['required']) {
								<span class="ml-1 text-red-500">*</span>
							}
							@if (userForm.get('email')!.errors?.['email']) {
								<span class="ml-1 text-orange-500">*</span>
							}
						</nz-form-label>
						<nz-form-control>
							<input nz-input formControlName="email" class="ant-input" autocomplete="email" />
						</nz-form-control>
					</nz-form-item>
					<nz-form-item>
						<nz-form-label>
							{{ 'invitations.toCompany.form.phoneNumber' | transloco }}
							@if (userForm.get('phoneNumber')!.validator) {
								<span class="ml-1 text-red-500">*</span>
							}
						</nz-form-label>
						<nz-form-control>
							<agdir-phone-number [control]="userForm.get('phoneNumber')!" />
						</nz-form-control>
					</nz-form-item>

					<nz-form-item>
						<nz-form-label>
							{{ 'invitations.toCompany.form.howToSend' | transloco }}
						</nz-form-label>
						<div class="flex-row-start gap-2">
							<label nz-checkbox formControlName="sendEmail">
								{{ 'invitations.toCompany.form.sendEmail' | transloco }}
							</label>
							<label nz-checkbox formControlName="sendSms">{{ 'invitations.toCompany.form.sendSms' | transloco }}</label>
						</div>
					</nz-form-item>
					<div class="flex-col-start w-full mb-4">
						<nz-form-label>
							{{ 'invitations.toCompany.form.chooseAccess' | transloco }}
						</nz-form-label>
						<agdir-select [options]="ownershipOptions" [selectControl]="$any(userForm.get('permission')!)" [horizontal]="false" />
					</div>
				</div>

				@if (showUSASMSPrompt()) {
					<agdir-alert type="warning" class="mb-2" icon="warning">
						Hello, farmers! We're upgrading to
						<strong>A2P 10DLC</strong>
						for better messages. During this, let's connect via email. This step ensures we're aligned with
						<strong>legal standards</strong>
						and keeps us connected.
					</agdir-alert>
				}

				<div class="flex-row-end gap-2 w-full">
					<ng-content select="[footerButtons]" />
					<agdir-button
						color="primary"
						[isProcessing]="isSending()"
						(click)="sendInvitation()"
						label="invitations.toCompany.form.sendInvitation"
						icon="send"
					/>
				</div>
			</form>
		</agdir-card>
	`,
	styles: [
		`
			:host {
				::ng-deep label {
					font-size: 1rem;
				}

				::ng-deep label::before {
					display: none !important;
				}
			}
		`,
		`
			:host ::ng-deep [type='tel'].invalid {
				@apply border-red-500;
			}
		`,
	],
	imports: [
		AgdirIconComponent,
		AgdirSelectComponent,
		NzCheckboxComponent,
		NzColDirective,
		NzFormControlComponent,
		NzFormDirective,
		NzFormItemComponent,
		NzFormLabelComponent,
		NzInputDirective,
		NzRowDirective,
		PhoneNumberComponent,
		ReactiveFormsModule,
		TranslocoPipe,
		AgdirCardComponent,
		ButtonComponent,
		AgdirAlertComponent,
	],
})
export class InviteCustomerCardComponent {
	userForm: FormGroup<UserForm> = new FormGroup<UserForm>({
		phoneNumber: new FormControl('', {
			nonNullable: true,
			validators: [Validators.required, Validators.minLength(6)],
		}),
		permission: new FormControl(CompanyAccessLevel.CRUD, { nonNullable: true, validators: [Validators.required] }),
		email: new FormControl('', { nonNullable: true, validators: [Validators.email, Validators.required] }),
		name: new FormControl('', { nonNullable: true }),
		surname: new FormControl('', { nonNullable: true }),
		sendSms: new FormControl(false, { nonNullable: true }),
		sendEmail: new FormControl(true, { nonNullable: true }),
	});
	formValueChangesSignal = toSignal(this.userForm.valueChanges);
	phoneValueChangesSignal = toSignal(this.userForm.get('phoneNumber')!.valueChanges);
	sendEmailChangesSignal = toSignal(this.userForm.get('sendEmail')!.valueChanges);
	showUSASMSPrompt = computed(() => this.phoneValueChangesSignal()?.startsWith('+1'));
	invitation: Signal<Partial<Invitation>> = computed(() => {
		const { phoneNumber, name, surname, email, sendSms, sendEmail, permission } = this.userForm.value;
		return {
			invitee: { phoneNumber, name, surname, email },
			sendSms,
			sendEmail,
			permission,
		} as Partial<Invitation>;
	});
	snackService = inject(AgdirSnackbarService);
	isSending = signal(false);
	private accessLevelToStringPipe = inject(AccessLevelToStringPipe);
	ownershipOptions = [
		{
			value: CompanyAccessLevel.OWNER,
			label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.OWNER),
		},
		{
			value: CompanyAccessLevel.ADVISOR,
			label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.ADVISOR),
		},
		{
			value: CompanyAccessLevel.CRUD,
			label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.CRUD),
		},
		// {value:CompanyAccessLevel.Read, label:this.accessLevelToStringPipe.transform(CompanyAccessLevel.Read)},
	];
	private invitationService = inject(InvitationService);
	private companyService = inject(CompanyService);

	constructor() {
		effect(() => {
			const emailControl = this.userForm.get('email')!;
			const sendEmail = this.sendEmailChangesSignal();
			if (sendEmail) {
				emailControl.addValidators([Validators.required, Validators.email]);
			} else if (!this.showUSASMSPrompt()) {
				emailControl.removeValidators(Validators.required);
			} else if (this.showUSASMSPrompt()) {
				this.userForm.get('sendEmail')!.setValue(true);
			}
		});
		effect(() => {
			const sendSmsControl = this.userForm.get('sendSms')!;
			const sendEmailControl = this.userForm.get('sendEmail')!;
			if (this.phoneValueChangesSignal()?.startsWith('+1')) {
				sendSmsControl.setValue(false);
				sendSmsControl.disable();
				sendEmailControl.setValue(true);
			} else {
				sendSmsControl.enable();
			}
		});
	}

	async sendInvitation() {
		this.isSending.set(true);
		await this.invitationService.sendInviteToNumber({
			...this.invitation(),
			company: await firstValueFrom(this.companyService.getCurrentCompany()),
		});
		this.isSending.set(false);
		this.invitationService.reload.next();
		this.userForm.reset();
		this.snackService.open('invitations.toCompany.form.invitationSent');
	}
}
