import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { PageNavigationItem } from './page-navigation.item';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { NgScrollbar } from 'ngx-scrollbar';
import { BadgeComponent } from '@agdir/ui/badge';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	standalone: true,
	selector: 'agdir-dropdown-menu-navigation',
	template: `
		<ng-content select="[before]"></ng-content>
		<ng-scrollbar class="max-h-[400px] w-full">
			<div class="flex-col-start gap-2">
				@for (item of items(); track $index) {
					@if (item) {
						<button
							routerLinkActive="activeRoute"
							class="{{ item.customClass || '' }}"
							(click)="activate(item)"
							[class.active]="item?.isActive ? item?.isActive() : false"
						>
							@if (item.icon) {
								<agdir-icon [icon]="item.icon" />
							}
							@if (item.image) {
								<img [src]="item.image" />
							}
							{{ item.label | transloco }}
							@if (item.hasNotifications) {
								<agdir-badge class="absolute top-0 right-0" color="dark-red" />
							}
						</button>
					} @else {
						<div class="w-full h-px bg-gray-300"></div>
					}
				}
			</div>
		</ng-scrollbar>
		<ng-content select="[after]"></ng-content>
	`,
	styles: [
		`
			:host {
				@apply flex-col-start gap-1 p-3;

				button {
					font-size: 1rem;
					@apply p-1 w-full text-left flex-row-start gap-1 items-center relative;

					&.active,
					&.activeRoute,
					&:hover,
					&:active,
					&:focus {
						@apply bg-blue-100;
					}
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [RouterLinkActive, RouterLink, TranslocoPipe, NgScrollbar, BadgeComponent, AgdirIconComponent],
})
export class DropdownMenuNavigationComponent {
	items = input<Array<PageNavigationItem | null>>([]);
	parentRouterLinks = input<string[]>([]);
	relativeTo = input<ActivatedRoute>();
	router = inject(Router);

	routerLink(item: PageNavigationItem) {
		console.log([...this.parentRouterLinks(), item.route]);
		return [...this.parentRouterLinks(), item.route];
	}

	activate(item: PageNavigationItem) {
		if (item.click) {
			item.click();
		} else if (item.route) {
			this.router.navigate(this.routerLink(item), { relativeTo: this.relativeTo() });
		}
	}
}
