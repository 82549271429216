import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { AgdirConfirmationConfig } from '../confirmation.types';
import { JsonPipe, NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	standalone: true,
	selector: 'agdir-confirmation-dialog',
	templateUrl: './dialog.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [NgIf, MatDialogClose, NgClass, MatButtonModule, TranslocoPipe, JsonPipe, AgdirIconComponent],
})
export class AgdirConfirmationDialogComponent {
	data: AgdirConfirmationConfig;

	constructor(
		@Inject(MAT_DIALOG_DATA) _data: AgdirConfirmationConfig,
		private transloco: TranslocoService,
	) {
		this.data = {
			..._data,
			params: Object.entries(_data.params || {}).reduce((acc, [key, value]) => {
				return { ...acc, [key]: this.transloco.translate(String(value)) };
			}, {}),
		};
	}
}
