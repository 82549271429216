import { ChangeDetectionStrategy, Component, HostBinding, input, model } from '@angular/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
	standalone: true,
	selector: 'agdir-weekday-select',
	template: `
		@if (label() && size() === 'normal') {
			<nz-form-label class="font-bold p-0" [class.leading-none]="horizontal()">{{ label() | transloco }}</nz-form-label>
		}
		@if (label() && size() === 'large') {
			<div class="text-3xl md:text-4xl font-bold" [class.leading-none]="horizontal()">{{ label() | transloco }}</div>
		}
		@if (label() && size() === 'medium') {
			<div class="text-xl font-light" [class.leading-none]="horizontal()">{{ label() | transloco }}</div>
		}
		@if (description && size() === 'large') {
			<div class="text-xl flex-1 font-light">{{ description() | transloco }}</div>
		}
		<nz-select
			nzMode="multiple"
			class="min-w-[100px]"
			[class.ml-2]="horizontal()"
			[ngModel]="weekDay()"
			(ngModelChange)="weekDay.set($event)"
			[nzPlaceHolder]="placeholder() | transloco"
			[nzOptions]="dayOptions"
		></nz-select>
	`,
	styles: [
		`
			:host {
				@apply flex items-center justify-start;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NzSelectModule, ReactiveFormsModule, NzFormModule, NzGridModule, TranslocoPipe, NzInputModule, FormsModule],
})
export class AgdirWeekdaySelectComponent {
	label = input<string>('');
	@HostBinding('class.flex-row')
	horizontal = input<boolean>(false);
	size = input<'normal' | 'large' | 'medium'>('normal');
	description = input<string>('');
	placeholder = input<string>('');
	weekDay = model.required<number[]>();

	dayOptions = [
		{ label: this.indexToDayInLocalIntl(0), value: 0 },
		{ label: this.indexToDayInLocalIntl(1), value: 1 },
		{ label: this.indexToDayInLocalIntl(2), value: 2 },
		{ label: this.indexToDayInLocalIntl(3), value: 3 },
		{ label: this.indexToDayInLocalIntl(4), value: 4 },
		{ label: this.indexToDayInLocalIntl(5), value: 5 },
		{ label: this.indexToDayInLocalIntl(6), value: 6 },
	];

	indexToDayInLocalIntl(day: number): string {
		const locale = navigator.language;
		const intl = new Intl.DateTimeFormat(locale, { weekday: 'short' });
		return intl.format(new Date(2024, 0, day + 1, 12)); // i am a professional
	}
}
