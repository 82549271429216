import { LocationType } from '@agdir/domain';
import { AgdirFeature } from '@agdir/features/domain';
import { AgdirNavigationItem } from '../components/navigation.types';

export const baseNavigation: AgdirNavigationItem[] = [
	{
		id: 'dashboard',
		title: 'moduleNavigationPage.dashboard',
		type: 'basic',
		icon: 'dashboard',
		link: 'dashboard',
	},
	{
		id: 'weather',
		title: 'moduleNavigationPage.weather',
		type: 'basic',
		icon: 'wb_sunny',
		link: 'weather',
	},
	{
		id: LocationType.field,
		title: 'moduleNavigationPage.fields',
		type: 'basic',
		icon: 'place',
		link: LocationType.field,
	},
	{
		id: 'journal',
		title: 'moduleNavigationPage.journal',
		type: 'group',
		link: 'journal',
		icon: 'menu_book',
		beta: true,
		children: [
			{
				id: 'crop-protection-certificate',
				title: 'moduleNavigationPage.journal:crop-protection-certificate',
				type: 'basic',
				icon: 'bug_report',
				link: 'crop-protection-certificate',
				feature: AgdirFeature.cropProtection,
			},
			{
				id: 'spraying',
				title: 'moduleNavigationPage.journal:spraying-journal',
				type: 'basic',
				icon: 'agdir_crop_protection',
				link: 'spraying',
				color: '#8e8f92',
				feature: AgdirFeature.cropProtection,
			},
			{
				id: 'soil-cultivation',
				title: 'moduleNavigationPage.journal:soil-cultivation',
				type: 'basic',
				icon: 'agriculture',
				link: 'soil-cultivation',
				feature: AgdirFeature.journalSoilCultivation,
			},
			{
				id: 'irrigation',
				title: 'moduleNavigationPage.journal:irrigation',
				type: 'basic',
				icon: 'water_drop',
				link: 'irrigation',
				feature: AgdirFeature.journalIrrigation,
			},
			{
				id: 'waste',
				title: 'moduleNavigationPage.journal:waste',
				type: 'basic',
				icon: 'delete_sweep',
				link: 'waste',
				color: '#CD661B',
				feature: AgdirFeature.journalWaste,
				beta: true,
			},
			{
				id: 'fertilization',
				title: 'moduleNavigationPage.journal:fertilization',
				type: 'basic',
				icon: 'compost',
				link: 'fertilization',
				feature: AgdirFeature.journalFertilizer,
				comingSoon: true,
			},

			{
				id: 'precipitation',
				title: 'moduleNavigationPage.journal:precipitation',
				type: 'basic',
				icon: 'rainy',
				link: 'precipitation',
				feature: AgdirFeature.precipitation,
				beta: true,
			},
			{
				id: 'hse-templates',
				title: 'moduleNavigationPage.journal:hse-templates',
				type: 'basic',
				icon: 'fact_check',
				link: 'hse-templates',
				feature: AgdirFeature.hse,
				beta: true,
			},
			{
				id: 'hse-rounds',
				title: 'moduleNavigationPage.journal:hse-rounds',
				type: 'basic',
				icon: 'done_all',
				link: 'hse-rounds',
				feature: AgdirFeature.hse,
				beta: true,
			},
			{
				id: 'crop-storage',
				title: 'moduleNavigationPage.journal:cropStorage',
				type: 'basic',
				icon: 'yard',
				link: 'crop-storage',
				feature: AgdirFeature.storage,
				comingSoon: true,
			},
			{
				id: 'harvest',
				title: 'moduleNavigationPage.journal:harvest',
				type: 'basic',
				icon: 'grass',
				link: 'harvest',
				feature: AgdirFeature.harvest,
				beta: true,
			},
			{
				id: 'fertilizer-plan',
				title: 'moduleNavigationPage.journal:fertilizerPlan',
				type: 'basic',
				icon: 'compost',
				link: 'fertilizer-plan',
				feature: AgdirFeature.fertilizerPlan,
				comingSoon: true,
			},
			{
				id: 'observations',
				title: 'moduleNavigationPage.journal:observations',
				type: 'basic',
				icon: 'search',
				link: 'observations',
				feature: AgdirFeature.journalObservations,
				comingSoon: true,
			},
		],
	},
	{
		id: LocationType.building,
		title: 'moduleNavigationPage.buildings',
		type: 'basic',
		icon: 'home',
		link: LocationType.building,
	},
	{
		id: 'devices',
		title: 'moduleNavigationPage.devices',
		type: 'basic',
		icon: 'device_hub',
		link: 'devices',
	},
	{
		id: 'people',
		title: 'moduleNavigationPage.people',
		type: 'basic',
		icon: 'group',
		link: 'people',
	},
	{
		id: 'machines',
		title: 'moduleNavigationPage.machines',
		type: 'basic',
		icon: 'agriculture',
		link: 'machines',
		feature: AgdirFeature.machines,
	},
	{
		id: 'chat',
		title: 'Chat',
		type: 'basic',
		icon: 'chat',
		link: 'chat',
		feature: AgdirFeature.chat,
		beta: true,
	},
	{
		id: 'shop',
		title: 'moduleNavigationPage.shop',
		type: 'basic',
		icon: 'shopping_cart',
		link: 'shop',
		feature: AgdirFeature.shop,
	},
	{
		id: 'file-manager',
		title: 'moduleNavigationPage.fileManager',
		type: 'basic',
		icon: 'attach_file',
		link: 'file-manager',
		feature: AgdirFeature.fileManager,
	},
	{
		id: 'ksl',
		title: 'KSL',
		type: 'basic',
		icon: 'checklist',
		link: 'journal/ksl',
		feature: AgdirFeature.ksl,
		guard: ({ company }) => company.countryOfOrigin?.toLowerCase() === 'no',
	},
	{
		id: 'digitalCourses',
		title: 'Kurs og webinar',
		type: 'basic',
		icon: 'school',
		link: 'digital-courses',
		feature: AgdirFeature.digitalCourses,
	},
	{
		id: 'inventory',
		title: 'moduleNavigationPage.inventory',
		type: 'group',
		icon: 'library_books',
		link: 'inventory',
		children: [
			{
				id: 'fertilizers',
				title: 'moduleNavigationPage.catalogue:fertilizers',
				type: 'basic',
				icon: 'compost',
				link: 'fertilizers',
				feature: AgdirFeature.journalFertilizer,
			},
			{
				id: 'pesticides',
				title: 'moduleNavigationPage.catalogue:pesticides',
				type: 'basic',
				icon: 'science',
				link: 'pesticides',
				feature: AgdirFeature.pestsCatalogue,
			},
		],
		// feature: AgdirFeature.INVENTORY,
	},
	{
		id: 'tasks-management',
		title: 'moduleNavigationPage.journal:tasks-management',
		type: 'basic',
		icon: 'add_task',
		link: 'tasks-management',
		feature: AgdirFeature.journalTasksManagement,
	},
];
