export function zoningColors(numberOfZones:number ) {
	switch (numberOfZones) {
		case 3:
			return [1, '#fc8d59', 2, '#ffffbf', 3, '#91cf60', '#aaa'];
		case 4:
			return [1, '#d7191c', 2, '#fdae61', 3, '#a6d96a', 4, '#1a9641', '#aaa'];
		case 5:
			return [1, '#d73027', 2, '#fc8d59', 3, '#fee08b', 4, '#d9ef8b', 5, '#91cf60', '#aaa'];
		case 6:
			return [1, '#d7191c', 2, '#fdae61', 3, '#ffffbf', 4, '#a6d96a', 5, '#1a9641', 6, '#1a9850', '#aaa'];
		case 7:
			return [1, '#d73027', 2, '#fc8d59', 3, '#fee08b', 4, '#ffffbf', 5, '#d9ef8b', 6, '#91cf60', 7, '#1a9850', '#aaa'];
		default:
			return [1, '#d73027', 2, '#fc8d59', 3, '#fee08b', 4, '#ffffbf', 5, '#d9ef8b', 6, '#91cf60', 7, '#1a9850', '#aaa'];
	}
}
