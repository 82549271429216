import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AgdirConfirmationConfig } from './confirmation.types';
import { AgdirConfirmationDialogComponent } from './dialog/dialog.component';
import { first, firstValueFrom, map } from 'rxjs';

const DEFAULT_CONFIG: AgdirConfirmationConfig = {
	title: 'Confirm action',
	message: 'Are you sure you want to confirm this action?',
	icon: {
		show: true,
		name: 'warning',
		color: 'warn',
	},
	actions: {
		confirm: {
			show: true,
			label: 'Confirm',
			color: 'warn',
		},
		cancel: {
			show: true,
			label: 'Cancel',
		},
	},
	dismissible: false,
	fullScreen: true,
};

@Injectable({ providedIn: 'root' })
export class AgdirConfirmationService {
	constructor(private _matDialog: MatDialog) {}

	open(config: AgdirConfirmationConfig = {}, modalConfig: MatDialogConfig = {}): MatDialogRef<AgdirConfirmationDialogComponent> {
		const userConfig = { ...DEFAULT_CONFIG, ...config };

		return this._matDialog.open(AgdirConfirmationDialogComponent, {
			autoFocus: false,
			disableClose: !userConfig.dismissible,
			data: userConfig,
			panelClass: ['agdir-confirmation-dialog-panel', config.fullScreen ? '' : 'skip-overrides'],
			...modalConfig,
		});
	}

	genericDelete(title?: string): Promise<boolean> {
		const dialogRef = this.open({
			title,
			message: 'general.deleteConfirmation.subtitle',
			translate: true,
			icon: {
				show: true,
				name: 'shield_question',
				color: 'error',
			},
			actions: {
				confirm: {
					show: true,
					label: `general.deleteConfirmation.yesDelete`,
					color: 'warn',
				},
				cancel: {
					show: true,
					label: `general.deleteConfirmation.noCancel`,
				},
			},
			dismissible: false,
		});
		return firstValueFrom(
			dialogRef.afterClosed().pipe(
				first(),
				map((resolution: string) => resolution === 'confirmed'),
			),
		);
	}
}
