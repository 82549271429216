import { AgdirDatatableEntity } from './agdir-datatable.entity';

export const convertTreeToList = (root: AgdirDatatableEntity): AgdirDatatableEntity[] => {
	const stack: AgdirDatatableEntity[] = [];
	const array: AgdirDatatableEntity[] = [];
	const hashMap = {};
	stack.push({ ...root, level: 0, expand: false });

	while (stack.length !== 0) {
		const node = stack.pop()!;
		visitNode(node, hashMap, array);
		if (node.children) {
			for (let i = node.children.length - 1; i >= 0; i--) {
				stack.push({ ...node.children[i], level: node.level! + 1, expand: false, parent: node });
			}
			node.expand = node.children?.some((child) => child.selected) || false;
		}
	}

	return array;
};

const visitNode = (
	node: AgdirDatatableEntity,
	hashMap: {
		[id: string]: boolean;
	},
	array: AgdirDatatableEntity[],
): void => {
	if (!hashMap[node.id]) {
		hashMap[node.id] = true;
		array.push(node);
	}
};
