import { Step } from './domain';

export const newCompanySteps: Step[] = [
	{
		title: 'newCompany.step2.navStepTitle',
		number: 'newCompany.step2.navStepNumber',
		description: 'newCompany.step2.description',
		path: 'step1',
		done: (companyBuilder) =>
			companyBuilder?.company()?.organizationName !== '' &&
			!!companyBuilder?.company()?.businessBranch &&
			companyBuilder?.company()?.organizationNr !== '' &&
			companyBuilder?.company()?.organizationVat !== '' &&
			companyBuilder?.company()?.countryOfOrigin !== '',
		next: null,
		previous: null,
	},
	{
		title: 'newCompany.step3.navStepTitle',
		number: 'newCompany.step3.navStepNumber',
		description: 'newCompany.step3.description',
		path: 'step2',
		done: (companyBuilder) =>
			companyBuilder?.company()?.address !== null &&
			companyBuilder?.company()?.address?.streetAddress !== '' &&
			companyBuilder?.company()?.address?.postal !== '' &&
			companyBuilder?.company()?.address?.city !== '' &&
			companyBuilder?.company()?.address?.country !== '',
		next: null,
		previous: null,
	},
	{
		title: 'newCompany.step4.navStepTitle',
		number: 'newCompany.step4.navStepNumber',
		description: 'newCompany.step4.description',
		path: 'step3',
		done: (companyBuilder) => (companyBuilder?.invitations() || []).length > 0,
		next: null,
		previous: null,
	},
	{
		title: 'newCompany.step5.navStepTitle',
		number: 'newCompany.step5.navStepNumber',
		description: 'newCompany.step5.description',
		path: 'step4',
		done: () => false,
		next: null,
		previous: null,
	},
];
