import { AgdirIconComponent } from '@agdir/agdir-icons';
import { AgdirSnackbarService } from '@agdir/alert';
import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, inject, input, ViewEncapsulation } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

export type BadgeColor =
	| 'primary'
	| 'secondary'
	| 'ghost'
	| 'green'
	| 'dark-blue'
	| 'blue'
	| 'dark-red'
	| 'red'
	| 'yellow'
	| 'gray'
	| 'orange'
	| 'brown'
	| 'purple';
export type BadgeSize = 'large' | 'default' | 'small';

@Component({
	standalone: true,
	selector: 'agdir-badge',
	template: `
		@if (icon(); as i) {
			<agdir-icon [icon]="i" />
		}
		@if (withDot()) {
			<i class="agdir-badge-dot"></i>
		}
		<ng-content select="[prefix]"></ng-content>
		@if (label()) {
			<span>{{ label() || '' | transloco }}</span>
		}
		<ng-content></ng-content>
		<ng-content select="[suffix]"></ng-content>
	`,
	styleUrl: './badge.component.scss',
	encapsulation: ViewEncapsulation.None, // Because we have theming on a body level.
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent, AgdirIconComponent, TranslocoPipe],
})
export class BadgeComponent {
	copyWhenClicked = input<string | number | boolean | 'true'>(false);
	icon = input<string | null>(null);
	color = input<BadgeColor>('gray');
	label = input<string, undefined | null | string | number>('', { transform: (value: string | null | undefined | number) => String(value) || '' });
	withDot = input<boolean, undefined | null | string | boolean>(false, {
		transform: (value: string | null | undefined | boolean) => value === 'true' || value === true || value === '',
	});

	/**
	 * @deprecated Don't Use `size` instead. Its small anyway.
	 */
	size = input<BadgeSize>('small');

	private snackService = inject(AgdirSnackbarService);

	@HostListener('click')
	async copyToClipboard() {
		if (this.copyWhenClicked() === false || this.copyWhenClicked() === 'false') {
			return;
		}
		const copyText = this.copyWhenClicked() !== '' ? this.copyWhenClicked() : this.label();
		await navigator.clipboard.writeText(String(copyText));
		this.snackService.open('general.copiedToClipboard');
	}

	@HostBinding('attr.color')
	get defaultColor() {
		return !this.color() ? 'secondary' : this.color();
	}
}
