import { Location, Share } from '@agdir/domain';
import { SharesService } from '@agdir/services';
import { AsyncPipe, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { firstValueFrom, Observable } from 'rxjs';
import { AgdirSelectComponent } from '../agdir-select.component';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ButtonComponent } from '@agdir/ui/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { AccessLevelToStringPipe } from '@agdir/heimdall';
import { RouterLink } from '@angular/router';
import {
	NzTableCellDirective,
	NzTableComponent,
	NzTbodyComponent,
	NzTheadComponent,
	NzThMeasureDirective,
	NzTrDirective,
} from 'ng-zorro-antd/table';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		AsyncPipe,
		NgForOf,
		AgdirSelectComponent,
		NzFormModule,
		NzGridModule,
		ButtonComponent,
		NzIconModule,
		TranslocoDirective,
		TranslocoPipe,
		AccessLevelToStringPipe,
		RouterLink,
		NzTableCellDirective,
		NzTableComponent,
		NzTbodyComponent,
		NzThMeasureDirective,
		NzTheadComponent,
		NzTrDirective,
	],
	selector: 'agdir-company-people',
	standalone: true,
	styles: [
		`
			table {
				@apply mx-auto;
			}
		`,
		`
			th {
				@apply text-left;
			}
		`,
		`
			td,
			th {
				padding: 0.2rem 0.5rem;
				@apply text-lg;
			}
		`,
	],
	template: `
		@if (props.label && props.size == 'normal') {
			<nz-form-label>{{ props.label }}</nz-form-label>
		}
		@if (props.label && props.size == 'large') {
			<div class="text-3xl md:text-4xl font-bold">{{ props.label }}</div>
		}
		@if (props.description && props.size == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ props.description }}</div>
		}

		<nz-table
			#basicTable
			nzSize="small"
			[nzData]="items$ | async"
			nzBordered
			nzShowSizeChanger
			[nzFrontPagination]="true"
			[nzPageSize]="20"
			[nzPageSizeOptions]="[10, 20, 30, 50, 100]"
		>
			<tbody>
				@for (item of basicTable.data; track $index) {
					<tr>
						<td class="w-[30px]">
							<input
								type="checkbox"
								[checked]="isSelected(item._id)"
								(change)="toggleSelection(item._id, $any($event.target).checked)"
							/>
						</td>
						<td>
							{{ item.customer?.name }} {{ item.customer?.surname }}
							<div class="text-sm">
								{{ 'company.access.' + item.permission | transloco }}
							</div>
						</td>
						<td class="w-[30px]">
							<agdir-button icon="edit" [routerLink]="['/', item.companyId, 'people', item.customerId]" />
						</td>
					</tr>
				}
			</tbody>
		</nz-table>
	`,
})
export class CompanyPeopleFieldTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
	label = input<string>();
	customFormControl = input<FormControl>();

	selectedValues$ = signal<Share | null>(null);
	items$: Observable<Share[]> = inject(SharesService).getCurrentCompanyCustomers();
	selectedItems = new Set<string>();

	get formlyOrCustomFormControl() {
		return this.customFormControl() || this.formControl;
	}

	async ngOnInit() {
		const allItems = await firstValueFrom(this.items$);
		const selectedOption = allItems.find((o) => o.customerId === this.formlyOrCustomFormControl.value?.customerId);
		(this.formControl.value || []).map((item: Location) => String(item._id)).forEach((locationId: string) => this.selectedItems.add(locationId));
		this.selectedValues$.set(selectedOption || null);
	}

	async toggleSelection(locationId: string, isChecked: boolean): Promise<void> {
		if (isChecked) {
			this.selectedItems.add(locationId);
		} else {
			this.selectedItems.delete(locationId);
		}
		await this.updateFormControlWithSelectedItems();
	}

	async toggleAll(isChecked: boolean): Promise<void> {
		if (!this.items$) {
			return;
		}
		const locations = await firstValueFrom(this.items$);
		this.selectedItems.clear();
		if (isChecked) {
			locations.forEach((location) => this.selectedItems.add(String(location._id)));
		}
		await this.updateFormControlWithSelectedItems();
	}

	isSelected(locationId: string): boolean {
		return this.selectedItems.has(locationId);
	}

	private async updateFormControlWithSelectedItems() {
		const selectedIds = [...this.selectedItems];
		const allItems = await firstValueFrom(this.items$);
		this.formControl.setValue(allItems.filter((item) => selectedIds.includes(String(item._id))));
		this.formControl.markAsDirty();
	}
}
