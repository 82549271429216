import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLineModule, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { EntryPageItemComponent } from './entry-page-item.component';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@NgModule({
	declarations: [EntryPageItemComponent, EntryPageItemComponent],
	exports: [EntryPageItemComponent, MatExpansionModule],
	imports: [CommonModule, MatLineModule, MatExpansionModule, MatListModule, MatRippleModule, AgdirIconComponent],
})
export class EntryPageItemModule {}
