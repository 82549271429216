import { Component, input } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { LabelComponent } from './label.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgForOf } from '@angular/common';
import { I18nModule } from '@agdir/i18n/angular';
import { MatRippleModule } from '@angular/material/core';

export enum WindConditionsEnum {
	No_wind = 'WIND:No_wind',
	Light_wind = 'WIND:Light_wind',
	Some_wind = 'WIND:Some_wind',
	Quite_windy = 'WIND:Quite_windy',
	Strong_wind = 'WIND:Strong_wind',
}

@Component({
	standalone: true,
	selector: 'agdir-wind-entry',

	template: `
		<agdir-label icon="air">
			{{ 'journal.weather.wind.label' | transloco }}
		</agdir-label>

		<div class="grid gap-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-5 mb-3">
			<button
				*ngFor="let condition of conditions"
				type="button"
				class="rounded-1"
				[class.selected]="condition === control().value"
				matRipple
				(click)="control().setValue(condition)"
			>
				{{ 'journal.weather.wind.' + condition | transloco }}
			</button>
		</div>
	`,
	styles: [
		`
			button {
				transition: all 200ms;
			}

			button:hover {
				background: rgba(0, 0, 0, 0.04);
			}

			button.selected {
				background: #405c70 !important;
				color: white;
			}
		`,
	],
	imports: [LabelComponent, MatButtonToggleModule, NgForOf, I18nModule, ReactiveFormsModule, MatRippleModule],
})
export class WindEntryComponent {
	conditions: WindConditionsEnum[] = [
		WindConditionsEnum.No_wind,
		WindConditionsEnum.Light_wind,
		WindConditionsEnum.Some_wind,
		WindConditionsEnum.Quite_windy,
		WindConditionsEnum.Strong_wind,
	];
	control = input<AbstractControl>(new UntypedFormControl());
}
