import { CameraUploadComponent, FileService, FileUploadComponent } from '@agdir/fillagring/angular';
import { AgdirFile } from '@agdir/fillagring/domain';
import { I18nService } from '@agdir/i18n/angular';
import { AgdirConfirmationService } from '@agdir/ui';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { BehaviorSubject, EMPTY, filter, first, Observable, of, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-formly-image',
	template: `
		<img
			*ngIf="imgUrl$ | async as imgUrl"
			alt=""
			class="object-cover object-center h-80 aspect-1 opacity-0 transition-all ease-in-out"
			[class.opacity-100]="loaded$ | async"
			(load)="loaded$.next(true)"
			[src]="imgUrl"
		/>
		<ng-container *ngIf="filesInProgress$ | async; else actions">
			<button disabled type="button" mat-icon-button>
				<agdir-spinner></agdir-spinner>
			</button>
		</ng-container>
		<button
			(click)="showToolbar = 'upload'"
			*ngIf="!this.formControl.value && !props['static']"
			type="button"
			mat-icon-button
			[disabled]="props.disabled"
		>
			<agdir-icon icon="add_a_photo" />
		</button>
		<button
			(click)="showToolbar = 'download'"
			*ngIf="this.formControl.value"
			type="button"
			class="absolute z-10 top-1 left-1 bg-blue-50 text-purple-950"
			mat-icon-button
		>
			<agdir-icon icon="more_vert" />
		</button>
		<ng-template #actions>
			<div
				*ngIf="showToolbar !== 'none' || (props['static'] && !this.formControl.value)"
				class="absolute z-50 flex flex-row space-x-1 bg-gray-200 rounded-full shadow py-1 px-1 align-middle top-1 left-1"
				[class.absolute]="this.formControl.value || !props['static']"
			>
				<button *ngIf="!props['static'] || this.formControl.value" (click)="showToolbar = 'none'" type="button" mat-icon-button>
					<agdir-icon icon="close" />
				</button>
				<button (click)="download()" *ngIf="showToolbar === 'download'" type="button" mat-icon-button>
					<agdir-icon icon="download" />
				</button>
				<agdir-file-upload (fileAdded)="upload($event)"></agdir-file-upload>
				<agdir-camera-upload (fileAdded)="upload($event)"></agdir-camera-upload>
				<button *ngIf="showToolbar === 'download' && this.formControl.value" (click)="showDeleteConfirmation()" type="button" mat-icon-button>
					<agdir-icon icon="delete" />
				</button>
			</div>
		</ng-template>
	`,
	styles: [
		`
			:host {
				display: block;
				position: relative;
			}
		`,
	],
	imports: [MatRippleModule, NgIf, AsyncPipe, FileUploadComponent, CameraUploadComponent, SpinnerComponent, MatButtonModule, AgdirIconComponent],
})
export class FormlyImageComponent extends FieldType<FieldTypeConfig> implements OnDestroy, OnInit {
	loaded$ = new BehaviorSubject<boolean>(false);
	showToolbar: 'upload' | 'download' | 'none' = 'none';
	filesInProgress$ = new BehaviorSubject<boolean>(false);
	destroyed$ = new Subject<any>();
	imgUrl$?: Observable<string | null>;

	constructor(
		private fileService: FileService,
		private agdirConfirmationService: AgdirConfirmationService,
		private i18nService: I18nService,
	) {
		super();
	}

	ngOnDestroy(): void {
		this.destroyed$.next(EMPTY);
		this.destroyed$.unsubscribe();
	}

	ngOnInit() {
		this.imgUrl$ = this.formControl.valueChanges.pipe(
			startWith(this.formControl.value),
			takeUntil(this.destroyed$),
			switchMap((s: AgdirFile) => (s ? this.fileService.download(s.path) : of(null))),
		);
	}

	async download() {
		const file = this.formControl.value;
		if (file?.path) {
			const url = await this.fileService.download(file.path);
			window.open(url, '_blank');
		}
	}

	async upload(file: File) {
		const folder = this.props['folder'];
		const uploadResult = await this.fileService.upload(file, folder);
		this.filesInProgress$.next(true);
		uploadResult.done
			.pipe(
				filter((done) => !!done),
				first(),
			)
			.subscribe(() => {
				this.showToolbar = 'none';
				this.filesInProgress$.next(false);
				this.formControl.setValue(uploadResult.toObject());
			});
	}

	async showDeleteConfirmation(): Promise<void> {
		if (await this.agdirConfirmationService.genericDelete()) {
			this.showToolbar = 'none';
			this.filesInProgress$.next(false);
			this.formControl.setValue(null);
		}
	}
}
