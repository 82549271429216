<div class="relative flex flex-col w-full h-full">
	<ng-container *ngIf="data.dismissible">
		<div class="absolute top-0 right-0 pt-1 pr-1">
			<button [matDialogClose]="undefined" mat-icon-button>
				<agdir-icon [icon]="'close'" class="" />
			</button>
		</div>
	</ng-container>

	<div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-1 pb-1 sm:pb-1">
		<ng-container *ngIf="data.icon?.show">
			<div
				[ngClass]="{
					'text-purple-500-600 bg-purple-500-100 dark:text-purple-500-50 dark:bg-purple-500-600': data.icon?.color === 'primary',
					'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon?.color === 'accent',
					'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon?.color === 'warn',
					'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon?.color === 'basic',
					'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon?.color === 'info',
					'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon?.color === 'success',
					'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon?.color === 'warning',
					'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon?.color === 'error'
				}"
				class="flex flex-0 items-center justify-center p-1.5 sm:mr-1 rounded-full">
				<agdir-icon [icon]="$any(data.icon?.name)" class="text-current" />
			</div>
		</ng-container>

		<ng-container *ngIf="data.title || data.message">
			<div
				class="flex flex-col items-center sm:items-start mt-1 sm:mt-0 sm:pr-1 space-y-1 text-center sm:text-left">
				<ng-container *ngIf="data.title &&!data.translate">
					<div [innerHTML]="data.title" class="text-xl leading-1 font-medium"></div>
				</ng-container>
				<ng-container *ngIf="data.title &&data.translate">
					<div [innerHTML]="data.title|transloco: data.params||{}"
						 class="text-xl leading-1 font-medium"></div>
				</ng-container>

				<ng-container *ngIf="data.message &&!data.translate">
					<div [innerHTML]="data.message" class=""></div>
				</ng-container>
				<ng-container *ngIf="data.message &&data.translate">
					<div [innerHTML]="data.message|transloco: data.params||{}" class=""></div>
				</ng-container>
			</div>
		</ng-container>
	</div>
	<ng-container *ngIf="data.actions?.confirm?.show || data.actions?.cancel?.show">
		<div
			class="flex items-center justify-center sm:justify-end px-1 py-1 space-x-1 bg-gray-50 dark:bg-black dark:bg-opacity-10">
			<ng-container *ngIf="data.actions?.cancel?.show">
				<button *ngIf="!data.translate" [innerHTML]="data.actions?.cancel?.label "
						[matDialogClose]="'cancelled'"
						mat-stroked-button></button>
				<button *ngIf="data.translate"
						[innerHTML]="(data.actions?.cancel?.label||'') |transloco: data.params||{} "
						[matDialogClose]="'cancelled'"
						mat-stroked-button></button>
			</ng-container>

			<ng-container *ngIf="data.actions?.confirm?.show">
				<button
					*ngIf="!data.translate"
					[color]="data.actions?.confirm?.color||''"
					[innerHTML]="data.actions?.confirm?.label"
					[matDialogClose]="'confirmed'"
					mat-flat-button></button>
				<button
					*ngIf="data.translate"
					[color]="data.actions?.confirm?.color||''"
					[innerHTML]="(data.actions?.confirm?.label||'') |transloco: data.params||{}"
					[matDialogClose]="'confirmed'"
					mat-flat-button></button>
			</ng-container>
		</div>
	</ng-container>
</div>
