import { Device } from '@agdir/domain';
import { I18nService } from '@agdir/i18n/angular';
import { DevicesService } from '@agdir/services';
import { AsyncPipe, JsonPipe, NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { firstValueFrom, map, Observable, startWith } from 'rxjs';

type SelectedValue = string | string[] | null;

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-devices',
	template: `
		<mat-form-field class="w-full">
			<mat-label>{{ props.label }}</mat-label>
			<mat-select
				[placeholder]="props.placeholder!"
				[value]="selectedValue$ | async"
				(selectionChange)="onSelectionChange($event)"
				[multiple]="props['multi']"
			>
				<mat-option *ngFor="let device of devices$ | async" [value]="device._id">
					{{ device.name }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	`,
	standalone: true,
	imports: [MatFormFieldModule, MatSelectModule, AsyncPipe, NgForOf, JsonPipe],
})
export class DevicesFieldTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
	devices$?: Observable<Device[]>;
	selectedValue$?: Observable<SelectedValue>;

	constructor(
		private devicesService: DevicesService,
		private translate: I18nService,
	) {
		super();
	}

	get isMulti() {
		return this.props['multi'];
	}

	ngOnInit() {
		this.devices$ = this.devicesService.fetchDevices().pipe(
			map((s) => {
				if (!this.props['emptyOption']) {
					return s;
				}
				return [
					{
						_id: null,
						name: this.translate.translate('modules.locationPage.editLocationDevice.unassignedLocation'),
					} as any,
					...s,
				];
			}),
		);

		this.selectedValue$ = this.formControl.valueChanges.pipe(
			startWith(this.formControl.value),
			map((s) => this.mapId(s)),
		);
	}

	async onSelectionChange(ev: MatSelectChange) {
		if (!this.devices$) {
			return;
		}
		const selected = await this.getSelected(ev);
		this.formControl.setValue(selected);
		this.formControl.markAsDirty();
	}

	private mapId(value: Device | Device[] | string | string[] | null): SelectedValue {
		if (!value) {
			return null;
		}

		if (this.props['valueAsId']) {
			return value as string | string[];
		}

		if (this.isMulti) {
			const mapped = (value as Device[]).map((s) => s._id as string);
			return mapped.length > 0 ? mapped : null;
		}
		return (value as Device)._id as string;
	}

	private async getSelected(ev: MatSelectChange): Promise<Device | Device[] | string | string[] | null | undefined> {
		if (!this.devices$) {
			return null;
		}

		const devices = await firstValueFrom(this.devices$);
		if (this.isMulti) {
			const filtered = devices.filter((s) => ev.value.includes(s._id));
			if (!filtered) {
				return null;
			}
			if (this.props['valueAsId']) {
				return filtered.map((s) => s._id as string);
			}
			return filtered;
		}

		const device = devices.find((s) => ev.value === s._id);
		if (this.props['valueAsId']) {
			return device?._id;
		}
		return device;
	}
}
