import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
	standalone: true,
	selector: 'agdir-weekday-select',
	template: `
		<nz-select formControlName="selectedDays" nzMode="multiple" nzPlaceHolder="Select days" [nzOptions]="dayOptions"></nz-select>
	`,
	imports: [NzSelectModule, ReactiveFormsModule],
})
export class AgdirWeekdaySelectComponent extends FieldType<FieldTypeConfig> {
	dayOptions = [
		{ label: this.indexToDayInLocalIntl(0), value: 0 },
		{ label: this.indexToDayInLocalIntl(1), value: 1 },
		{ label: this.indexToDayInLocalIntl(2), value: 2 },
		{ label: this.indexToDayInLocalIntl(3), value: 3 },
		{ label: this.indexToDayInLocalIntl(4), value: 4 },
		{ label: this.indexToDayInLocalIntl(5), value: 5 },
		{ label: this.indexToDayInLocalIntl(6), value: 6 },
	];

	indexToDayInLocalIntl(day: number): string {
		const locale = navigator.language;
		const intl = new Intl.DateTimeFormat(locale, { weekday: 'short' });
		return intl.format(new Date(2024, 0, day + 1, 12)); // i am a professional
	}
}
