import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-html',
	standalone: true,
	template: `
		{{ props['text'] | transloco }}
	`,
	imports: [TranslocoPipe],
})
export class HtmlComponent extends FieldType<FieldTypeConfig> {}
