import { I18nService } from '@agdir/i18n/angular';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FieldArrayType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-add-active-ingredients',
	template: `
		<mat-toolbar class="hidden md:pl-6 md:flex md:justify-between md:text-sm">
			<span class="md:w-1/4">{{ inputNameLabel }}</span>
			<span class="md:w-1/4">{{ inputAmountLabel }}</span>
			<span class="md:w-1/4">{{ inputUnitsLabel }}</span>
			<span></span>
		</mat-toolbar>
		<mat-divider class="hidden md:flex md:pl-6 md:mb-4"></mat-divider>
		<div class="flex" *ngFor="let field of field.fieldGroup; let i = index">
			<formly-field [field]="field" class="flex-1"></formly-field>
			<button mat-icon-button type="button" (click)="remove(i)" [disabled]="form.disabled">
				<agdir-icon icon="close" />
			</button>
		</div>
		<div class="flex justify-center">
			<button mat-stroked-button type="button" (click)="add()" [disabled]="form.disabled">
				<agdir-icon icon="add" />
				<span *ngIf="props['showButtonLabel']">{{ buttonLabel }}</span>
			</button>
		</div>
	`,

	imports: [MatToolbarModule, MatDividerModule, NgForOf, FormlyModule, MatButtonModule, NgIf, JsonPipe, AgdirIconComponent],
})
export class AddActiveIngredientsComponent extends FieldArrayType<FieldTypeConfig> implements OnInit {
	ingredients?: UntypedFormArray;
	buttonLabel = this.i18nService.translate('general.activeIngredients.add');
	inputNameLabel = this.i18nService.translate('general.activeIngredients.name');
	inputAmountLabel = this.i18nService.translate('general.activeIngredients.amount');
	inputUnitsLabel = this.i18nService.translate('general.activeIngredients.units');

	defaultLabels = true;

	constructor(private i18nService: I18nService) {
		super();
	}

	ngOnInit() {
		this.buttonLabel = this.i18nService.translate(this.props['buttonLabel']);
		if (!this.defaultLabels) {
			this.inputNameLabel = this.i18nService.translate(this.props['inputNameLabel']);
			this.inputAmountLabel = this.i18nService.translate(this.props['inputAmountLabel']);
			this.inputUnitsLabel = this.i18nService.translate(this.props['inputUnitsLabel']);
		}
	}
}
