import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { provideTranslocoScope, TranslocoService } from '@ngneat/transloco';
import { NewCompanyPageComponent } from './new-company-page.component';
import { CompanyBuilderAddressComponent } from './views/company-builder-address.component';
import { CompanyBuilderCredentialsComponent } from './views/company-builder-credentials.component';
import { CompanyBuilderInvitationsComponents } from './views/company-builder-invitations-components.component';
import { NewCompanyStep5Component } from './views/new-company-step5.component';

export const NEW_COMPANY_ROUTES: Routes = [
	{
		path: '',
		component: NewCompanyPageComponent,
		providers: [provideTranslocoScope({ scope: 'newCompany', alias: 'newCompany' })],
		resolve: {
			translations: () => inject(TranslocoService).load('newCompany'),
		},
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'step1',
			},
			{
				path: 'step1',
				component: CompanyBuilderCredentialsComponent,
				data: { title: 'newCompany.step2.navStepTitle', number: 'newCompany.step2.navStepNumber' },
			},
			{
				path: 'step2',
				component: CompanyBuilderAddressComponent,
				data: { title: 'newCompany.step3.navStepTitle', number: 'newCompany.step3.navStepNumber' },
			},
			{
				path: 'step3',
				component: CompanyBuilderInvitationsComponents,
				data: { title: 'newCompany.step4.navStepTitle', number: 'newCompany.step4.navStepNumber' },
			},
			{
				path: 'step4',
				component: NewCompanyStep5Component,
				data: { title: 'newCompany.step5.navStepTitle', number: 'newCompany.step5.navStepNumber' },
			},
		],
	},
];
