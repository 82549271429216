import { AgdirFeatures } from '@agdir/domain';
import { FeatureToggleService } from '@agdir/features/angular';
import { Injectable } from '@angular/core';
import { catchError, combineLatest, map, Observable, of, shareReplay, startWith } from 'rxjs';
import { AgdirNavigationItem, NavigationGuard } from '../components/navigation.types';
import { AuthService, CompanyService } from '@agdir/services';
import { baseNavigation } from './base-navigation';

const isAllowedByFeature = (item: AgdirNavigationItem, features: AgdirFeatures): boolean => !item.feature || features[item.feature];
const isAllowedByGuard = (item: AgdirNavigationItem, guard: NavigationGuard): boolean => !item.guard || item.guard(guard);
const runNavigationGuards = (items: AgdirNavigationItem[] = [], guard: NavigationGuard): AgdirNavigationItem[] => {
	return items
		.filter((item) => isAllowedByFeature(item, guard.features))
		.filter((item) => isAllowedByGuard(item, guard))
		.map((item) => ({
			...item,
			children: item.children?.length ? runNavigationGuards(item.children, guard) : item.children,
		}))
		.filter((item) => item.type !== 'group' || item.children?.length);
};

@Injectable({ providedIn: 'root' })
export class NavigationService {
	baseNavigation: AgdirNavigationItem[] = baseNavigation;
	navigation$: Observable<AgdirNavigationItem[]> = this.initNavigation().pipe(shareReplay(1));

	constructor(
		private companyService: CompanyService,
		private authService: AuthService,
		private featureToggleService: FeatureToggleService,
	) {}

	protected initNavigation(): Observable<AgdirNavigationItem[]> {
		return combineLatest([
			this.companyService.getCurrentCompany().pipe(startWith(null)),
			this.featureToggleService.getAllFeatures().pipe(startWith({} as AgdirFeatures)),
			this.authService.getCurrentCustomerSession(),
		]).pipe(
			map(([company, features, customer]) => {
				if (!company || !features) {
					return this.baseNavigation.filter((item) => !item.feature && !item.guard);
				}
				return runNavigationGuards(this.baseNavigation, {
					company,
					features,
					customer,
				});
			}),
			catchError(() => of([])),
		);
	}
}
