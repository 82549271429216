import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'agdir-version',
	template: `
		<div class="opacity-50 text-sm flex flex-col items-center justify-center gap-1">
			<span>&copy; {{ date | date: 'y' }}</span>
			<span>Agdir Drift A/S</span>
			<span title="{{ version().BUILD || '?' }}">v{{ minorMajor() }}</span>
		</div>
	`,
	imports: [CommonModule],
})
export class VersionComponent {
	date = new Date();
	version = input<{ BUILD: string; DATE: string }>();
	minorMajor = () => this.version()?.BUILD.split('.').slice(0, 2).join('.') || '?';
}
