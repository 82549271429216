import { AgdirSelectComponent, FormlyStuff } from '@agdir/agdir-forms';
import { AgdirCardComponent, getCustomerLocationInformation } from '@agdir/core/angular';
import { BusinessBranch, businessBranches, Company, SUOPPORTED_COUNTRIES } from '@agdir/domain';
import { I18nModule } from '@agdir/i18n/angular';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideTranslocoScope } from '@ngneat/transloco';
import { FormlyConfig, FormlyModule } from '@ngx-formly/core';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NewCompanyService } from '../new-company.service';
import { AgdirFormlyCompanyRegistryFinderComponent } from './company-registry-finder-formly.component';

@Component({
	standalone: true,
	selector: 'agdir-company-builder-credentials',
	providers: [provideTranslocoScope('newCompany')],
	template: `
		<formly-form
			[fields]="formly.fields"
			[form]="formly.form"
			[model]="formly.model ? formly.model() : {}"
			(modelChange)="companyBuilder.addCompanyBaseInformation($event)"
		/>
	`,
	imports: [
		I18nModule,
		NzSelectModule,
		FormlyModule,
		AsyncPipe,
		FormsModule,
		NgIf,
		ReactiveFormsModule,
		SpinnerComponent,
		AgdirCardComponent,
		AgdirSelectComponent,
		AgdirFormlyCompanyRegistryFinderComponent,
	],
})
export class CompanyBuilderCredentialsComponent implements OnDestroy {
	newCompanyService = inject(NewCompanyService);
	formly = this.makeFormly();
	companyBuilder = this.newCompanyService.companyBuilder;
	destroyed$ = new Subject<void>();

	private readonly formlyConfig = inject(FormlyConfig);

	constructor() {
		this.formlyConfig.setType([{ name: 'agdir-company-registry-finder', component: AgdirFormlyCompanyRegistryFinderComponent }]);

		this.prePopulateModel();
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	makeFormly(): FormlyStuff<Signal<Company>> {
		return {
			form: new FormGroup({}),
			model: this.newCompanyService.companyBuilder.company as Signal<Company>,
			fields: [
				{
					className: 'max-w-md mb-5',
					key: 'countryOfOrigin',
					type: 'select',
					props: {
						translate: true,
						label: 'newCompany.form.country.label',
						description: 'newCompany.form.country.description',
						required: true,
						valueProp: 'value',
						labelProp: 'label',
						options: this.makeCountriesOptions(),
					},
				},

				{
					key: 'organizationNr',
					className: 'max-w-md mb-5 flex-1',
					type: 'agdir-company-registry-finder',
					props: {
						type: 'text',
						translate: true,
						required: true,
						label: 'newCompany.form.organizationNr.label',
						description: 'newCompany.form.organizationNr.description',
						searchDisabled: toObservable(this.newCompanyService.companyBuilder.company).pipe(map((f) => f.countryOfOrigin !== 'NO')),
						onComanySearchResult: (company: Company) => {
							this.companyBuilder.addCompanyBaseInformation(company);
						},
					},
					hooks: {
						onInit: (field) =>
							field.formControl?.valueChanges
								.pipe(takeUntil(this.destroyed$))
								.subscribe((newValue) => field.form?.get('organizationVat')?.patchValue(newValue)),
					},
					validation: {
						messages: {
							required: 'newCompany.form.organizationNr.required',
						},
					},
				},
				{
					key: 'organizationVat',
					className: 'max-w-md mb-5',
					type: 'input',
					props: {
						translate: true,
						label: 'newCompany.form.organizationVat.label',
						description: 'newCompany.form.organizationVat.description',
					},
				},
				{
					key: 'organizationName',
					className: 'max-w-md mb-5',
					type: 'input',
					props: {
						translate: true,
						label: 'newCompany.form.organizationName.label',
						description: 'newCompany.form.organizationName.description',
						required: true,
					},
					validation: {
						messages: {
							required: 'newCompany.form.organizationName.required',
						},
					},
					hooks: {
						onInit: (field) =>
							field.formControl?.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((newValue) => {
								if (!field.form?.get('farmName')?.value) {
									field.form?.get('farmName')?.patchValue(newValue);
								}
							}),
					},
				},
				{
					key: 'farmName',
					className: 'max-w-md mb-5',
					type: 'input',
					props: {
						translate: true,
						label: 'newCompany.form.farmName.label',
						description: 'newCompany.form.farmName.description',
						required: true,
					},
					validation: {
						messages: {
							required: 'newCompany.form.farmName.required',
						},
					},
				},
				{
					key: 'businessBranch',
					type: 'input',
					props: {
						value: BusinessBranch.FARM,
						type: 'hidden',
					},
				},
				// {
				// 	key: 'businessBranch',
				// 	className: 'max-w-md mb-5',
				// 	type: 'select',
				// 	props: {
				// 		translate: true,
				// 		label: 'newCompany.form.businessBranch.label',
				// 		description: 'newCompany.form.businessBranch.description',
				// 		required: true,
				// 		valueProp: 'value',
				// 		labelProp: 'label',
				// 		options: businessBranches.map((br) => ({ label: br.name, value: br.value })),
				// 	},
				// 	validation: {
				// 		messages: {
				// 			required: 'newCompany.form.businessBranch.required',
				// 		},
				// 	},
				// },
			],
		};
	}

	makeCountriesOptions(): Array<{ label: string; value: string }> {
		return SUOPPORTED_COUNTRIES.map((br) => ({ label: br.name, value: br.countryCode }));
	}

	private async prePopulateModel() {
		if (!this.companyBuilder.company()?.businessBranch) {
			const businessBranch = businessBranches[0].value as BusinessBranch;
			this.companyBuilder.addCompanyBaseInformation({ businessBranch });
		}
		if (!this.companyBuilder.company()?.countryOfOrigin) {
			const { country: countryOfOrigin } = await getCustomerLocationInformation();
			this.companyBuilder.addCompanyBaseInformation({ countryOfOrigin });
		}
	}
}
