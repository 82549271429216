import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthCandidateSession, SmsError } from '@agdir/bifrost';
import { ActivatedRoute, Route, Router, Routes } from '@angular/router';

import { FormlyStuff } from '@agdir/agdir-forms';
import { ViewBaseComponent } from './view-base.component';
import { CUSTOMER_PATHS } from '@agdir/services';

@Injectable({ providedIn: 'root' })
export class ViewService {
	readonly authCandidate$ = new BehaviorSubject<AuthCandidateSession>({
		session: '',
		phone: '',
		username: '',
	});
	readonly viewTitle = signal<string>('');
	readonly viewSubtitle = signal<string>('');
	readonly viewNextLabel = signal<string>('');
	readonly viewPreviousLabel = signal<string>('');
	/**
	 * This is injected in root so the activated route is the base route, not the Quick Component route
	 */
	readonly currentQuickFlowComponentRoute$ = new BehaviorSubject<ActivatedRoute>(this.route);
	readonly customerFlow = new BehaviorSubject<Routes>([]);
	readonly currentFormlyStuff = signal<FormlyStuff<any> | null>(null);
	currentStepComponent = signal<ViewBaseComponent | null>(null);
	progress$ = signal<boolean>(false);
	error$ = signal<SmsError | null>(null);

	constructor(
		private router: Router,
		private route: ActivatedRoute,
	) {}

	async goToNextStep(nextStepIncrement = 1) {
		const nextStep = this.getNextStep(nextStepIncrement);
		if (!!nextStep) {
			await this.router.navigate([nextStep.path], {
				relativeTo: this.currentQuickFlowComponentRoute$.value.parent,
				// skipLocationChange: true,
			});
		} else {
			await this.gotToShell();
		}
	}

	async goToPreviousStep() {
		const previousStep = this.getPreviousStep();
		if (!!previousStep) {
			await this.router.navigate([previousStep.path], {
				relativeTo: this.currentQuickFlowComponentRoute$.value.parent,
				// skipLocationChange: true,
			});
		}
	}

	getNextStep(nextStepIncrement = 1): Route | null {
		const currentComponentInRoutes = this.customerFlow.value.find((route) => route.component === this.currentStepComponent()?.constructor);
		if (currentComponentInRoutes) {
			const currentRouteIndexInFlow = this.customerFlow.value.indexOf(currentComponentInRoutes);
			return currentRouteIndexInFlow < this.customerFlow.value.length + nextStepIncrement
				? this.customerFlow.value[currentRouteIndexInFlow + nextStepIncrement] || null
				: null;
		}
		return null;
	}

	getPreviousStep(): Route | null {
		const currentComponentInRoutes = this.customerFlow.value.find((route) => route.component === this.currentStepComponent()?.constructor);
		if (currentComponentInRoutes) {
			const currentRouteIndexInFlow = this.customerFlow.value.indexOf(currentComponentInRoutes);
			return currentRouteIndexInFlow > 0 ? this.customerFlow.value[currentRouteIndexInFlow - 1] || null : null;
		}
		return null;
	}

	hasNextStep(nextStepIncrement = 1): boolean {
		return this.getNextStep(nextStepIncrement) !== null;
	}

	hasPreviousStep(): boolean {
		return this.getPreviousStep() !== null;
	}

	registerCurrentStepComponent(cmp: ViewBaseComponent) {
		this.currentStepComponent.set(cmp);
	}

	async submitStep(e?: Event) {
		this.progress$.set(true);
		const canProceed = await this.currentStepComponent()?.submitStep(e);
		this.progress$.set(false);
		if (canProceed) {
			await this.goToNextStep();
		}
	}

	private gotToShell() {
		return this.router.navigateByUrl(CUSTOMER_PATHS.ALL_FARMS);
	}
}
