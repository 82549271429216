import { Pipe, PipeTransform } from '@angular/core';

const mega = 1000000;
const kilo = 1000;

@Pipe({
	standalone: true,
	name: 'humanSize',
})
export class HumanSizePipe implements PipeTransform {
	transform(value: number): unknown {
		return +value > kilo ? (value / mega).toFixed(2) + 'Mb' : value + 'Kb';
	}
}
