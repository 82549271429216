import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComingSoonComponent } from '../../../../../apps/shell-farm/src/app/components/coming-soon/coming-soon.component';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-page',
	template: `
		<agdir-coming-soon class="mb-1 md:mb-3" />

		<ng-content></ng-content>
	`,
	styles: [
		`
			:host {
				min-height: calc(100dvh - 64px);
				@apply flex-col-start flex-1 pt-1.5 md:pt-2 pb-2 md:pb-2
				max-md:px-2 mx-auto
				w-full md:w-[504px] lg:w-[1032px] xl:w-[1560px];
			}
		`,
	],
	imports: [ComingSoonComponent],
})
export class PageComponent {}
