import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, NgZone, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@Component({
	standalone: true,
	selector: 'agdir-mapbox-checkbox-control',
	template: `
		<button
			(click)="toggle()"
			matRipple
			class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5"
			[class.text-purple-500]="show()"
			type="button"
		>
			<label nz-checkbox [ngModel]="show()"></label>
			<span>{{ label }}</span>
		</button>
	`,
	styles: [
		`
			:host label {
				pointer-events: none;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NzCheckboxModule, FormsModule, AsyncPipe],
})
export class MapboxCheckboxComponent {
	label?: string;
	onTogglebuttonClick = new EventEmitter<boolean>();
	show = signal(true);

	private zone = inject(NgZone);

	toggle() {
		this.zone.run(() => {
			this.show.update((val) => !val);
			this.onTogglebuttonClick.emit(this.show());
		});
	}
}
