import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, input, model, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { twMerge } from 'tailwind-merge';

export interface AgdirSelectOption<T = any> {
	selected?: boolean;
	label: string;
	value: T;
	description?: string;
}

export type SelectSizeType = 'large' | 'default' | 'small';

export type AgdirSelectolor =
	| 'primary'
	| 'secondary'
	| 'ghost'
	| 'default'
	| 'gray'
	| 'brown'
	| 'icon'
	| 'yellow'
	| 'blue'
	| 'orange'
	| 'light-blue'
	| 'red'
	| 'green'
	| 'outline-white'
	| 'outline-green'
	| 'outline-orange'
	| 'outline-light-blue'
	| 'outline-gray'
	| 'outline-red';

function selectBase() {
	return `min-w-[50px] [&>nz-select-top-control]:border-normal [&>nz-select-top-control]:shadow-none [&>nz-select-top-control]:text-purple-950 [&>nz-select-top-control]:flex [&>nz-select-top-control]:items-center [&>nz-select-top-control]:rounded-[5px] [&>.ant-select-arrow]:text-theme-gray`;
}

function selectClass(className: string) {
	return twMerge(selectBase(), className);
}

export const selectColorMapSet = new Map<AgdirSelectolor, string>([
	['default', selectClass('')],
	[
		'gray',
		selectClass(
			'[&>nz-select-top-control]:bg-blue-50 [&>nz-select-top-control]:text-blue-50 [&>nz-select-top-control>nz-select-placeholder]:text-blue-50 [&>nz-select-arrow.ant-select-arrow]:text-blue-50',
		),
	],
	[
		'light-blue',
		selectClass(
			'[&>nz-select-top-control]:bg-purple-500 [&>nz-select-top-control]:hover:bg-purple-500-hbr [&>nz-select-top-control]:text-blue-50 [&>nz-select-top-control>nz-select-placeholder]:text-blue-50 [&>nz-select-arrow.ant-select-arrow]:text-blue-50',
		),
	],
]);

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-select-model',
	template: `
		@if (label() && size() == 'normal') {
			<nz-form-label [class.leading-none]="horizontal()">{{ label() | transloco }}</nz-form-label>
		}
		@if (label() && size() == 'large') {
			<div class="text-3xl md:text-4xl font-bold" [class.leading-none]="horizontal()">{{ label() | transloco }}</div>
		}
		@if (label() && size() == 'medium') {
			<div class="text-xl font-light" [class.leading-none]="horizontal()">{{ label() | transloco }}</div>
		}
		@if (description() && size() == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ description() | transloco }}</div>
		}
		<nz-select
			[ngModel]="value()"
			(nzOpenChange)="opened.emit($event)"
			(ngModelChange)="value.set($event)"
			[nzDisabled]="disabled() === 'true' || disabled() === true"
			[class.ml-2]="horizontal() === 'true' || horizontal() === true"
			[nzPlaceHolder]="placeholder()"
			[nzShowArrow]="true"
			[nzDropdownMatchSelectWidth]="false"
			[nzLoading]="isProcessing()"
			[nzSize]="selectSize()"
			[nzCustomTemplate]="defaultTemplate"
			[ngClass]="selectClass()"
			nzMode="default"
			class="w-full"
		>
			@for (fo of options(); track $index) {
				<nz-option [nzValue]="fo.value" [nzLabel]="fo.label | transloco"></nz-option>
			}
		</nz-select>
		<ng-template #defaultTemplate let-selected>
			{{ valuePrefix() | transloco }}
			{{ selected.nzLabel }}
		</ng-template>
	`,
	styles: [
		`
			:host {
				@apply flex justify-start flex-col;
			}
		`,
		`
			:host.pill ::ng-deep > nz-select > nz-select-top-control {
				/*@apply !rounded-full !pl-2 !pr-1 !py-1 !h-[28px];*/
			}

			:host ::ng-deep > nz-select .ant-select-selection-placeholder {
				transform: none;
				@apply relative inset-0;
			}

			:host.inline ::ng-deep .ant-select:not(.ant-select-customize-input) .ant-select-selector {
				border: none;
			}

			:host.inline ::ng-deep .ant-select-single .ant-select-selector .ant-select-selection-item,
			.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after,
			:host.inline ::ng-deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
				line-height: 1;
			}

			:host.inline ::ng-deep .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
				height: auto;
			}
		`,
	],
	imports: [NzOptionComponent, TranslocoPipe, NzFormLabelComponent, NzSelectComponent, FormsModule, NgClass],
})
export class AgdirSelectModelComponent<T = any> {
	value = model<any>();

	@HostBinding('class.flex-row') @HostBinding('class.items-center') get classHorizontal() {
		return this.horizontal() === 'true';
	}

	display = input<'inline' | 'normal'>('normal');

	horizontal = input<'false' | 'true' | boolean>();

	@HostBinding('class.inline') get borderless() {
		return this.display() === 'inline';
	}

	size = input<'normal' | 'large' | 'medium' | 'default'>('default');
	selectSize = input<SelectSizeType>('default');
	options = input<AgdirSelectOption[]>([]);
	label = input<string>('');
	description = input<string>('');
	valuePrefix = input<string>('');
	placeholder = input<string>('');
	disabled = input<'true' | 'false' | boolean>(false);
	color = input<AgdirSelectolor>('default');
	isProcessing = input<boolean>(false);
	selectClass = computed(() => selectColorMapSet.get(this.color()));

	opened = output<boolean>();
}
