import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@agdir/services';
import { ViewService } from './view.service';
import { FormlyStuff } from '@agdir/agdir-forms';
import { Title } from '@angular/platform-browser';
import { I18nService } from '@agdir/i18n/angular';
import { Subject, takeUntil } from 'rxjs';

@Component({
	standalone: true,
	selector: 'agdir-quick-page-base',
	template: ``,
})
export abstract class ViewBaseComponent<T = any> implements OnInit, OnDestroy {
	destroyed$ = new Subject<void>();
	protected formlyStuff = signal<FormlyStuff<T> | null>(null);
	protected route = inject(ActivatedRoute);
	protected router = inject(Router);
	protected service = inject(ViewService);
	protected auth = inject(AuthService);
	private title = inject(Title);
	private i18nService = inject(I18nService);

	ngOnDestroy(): void {
		this.service.currentFormlyStuff.set(null);
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	ngOnInit() {
		this.service.viewTitle.set(this.route.snapshot.data['title']);
		if (this.service.viewTitle()) {
			const titleWihtoutFirstPart = this.service.viewTitle().split('.').slice(1).join('.');
			this.i18nService
				.selectTranslate(titleWihtoutFirstPart, undefined, { scope: 'quick-auth' })
				.pipe(takeUntil(this.destroyed$))
				.subscribe((title) => this.title.setTitle(title));
		}
		this.service.viewSubtitle.set(this.route.snapshot.data['subtitle']);
		this.service.viewNextLabel.set(this.route.snapshot.data['nextLabel']);
		this.service.viewPreviousLabel.set(this.route.snapshot.data['previousLabel']);
		this.service.registerCurrentStepComponent(this);
		this.service.currentFormlyStuff.set(this.formlyStuff() || null);
	}

	/**
	 * Do not call this method directly, use the service.submitStep instead
	 * @param event
	 */
	abstract submitStep(event?: Event): Promise<boolean>;
}
