import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';

@Injectable({ providedIn: 'root' })
export class VerticalStepperService {
	busy$ = new BehaviorSubject<boolean>(false);
	private matStepper = new ReplaySubject<MatStepper | null>(1);

	registerStepperInstance(stepper: MatStepper): void {
		this.matStepper.next(stepper);
	}

	deregisterStepperInstance(): void {
		this.matStepper.next(null);
	}

	getStepperInstance(): Observable<MatStepper | null> {
		return this.matStepper;
	}
}
