import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, Directive, Host, ViewContainerRef } from '@angular/core';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { filter } from 'rxjs';
import { MapboxCustomControlWrapper } from '../../map/controls/mapbox-custom-control-wrapper';
import { MapFullscreenComponent } from './map-fullscreen.component';
import { FeatureToggleService } from '@agdir/features/angular';
import { AgdirFeature } from '@agdir/features/domain';
import { switchMap } from 'rxjs/operators';

@Directive({
	standalone: true,
	selector: '[agdirMapFullScreen]',
})
export class MapFullscreenDirective extends BaseDirective implements AfterContentInit {
	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		private featureService: FeatureToggleService,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$
			.pipe(
				switchMap(() => this.featureService.getFeature(AgdirFeature.digimapZoningLayers)),
				filter((disabled) => !disabled),
			)
			.subscribe(() => {
				const componentRef = this.viewContainerRef.createComponent(MapFullscreenComponent);
				this.controlComponent.control = new MapboxCustomControlWrapper(componentRef.location.nativeElement);
				this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
			});
	}
}
