import { CompanyAccessLevel } from '@agdir/domain';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
	standalone: true,
	name: 'accessLevelToString',
})
@Injectable({ providedIn: 'root' })
export class AccessLevelToStringPipe implements PipeTransform {
	constructor(private i18nService: TranslocoService) {}

	transform(value: CompanyAccessLevel | null, ...args: unknown[]): string {
		return value ? this.i18nService.translate(`company.access.${value?.toString()}`) || value?.toString() : '';
	}
}
