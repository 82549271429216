import { JsonPipe } from '@angular/common';
import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzFormControlComponent, NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form';
import { NzInputModule, NzTextareaCountComponent } from 'ng-zorro-antd/input';

@Component({
	standalone: true,
	selector: 'agdir-textarea-model',
	template: `
		<nz-form-item>
			<nz-form-control>
				@if (label() && size() == 'normal') {
					<nz-form-label>{{ translate() ? (label() | transloco) : label() }}</nz-form-label>
				}
				@if (label() && size() == 'large') {
					<div class="text-3xl md:text-4xl font-bold">{{ translate() ? (label() | transloco) : label() }}</div>
				}
				@if (description() && size() == 'large') {
					<div class="text-xl flex-1 font-light mb-5">{{ translate() ? (description() | transloco) : description() }}</div>
				}
				<nz-textarea-count [nzMaxCharacterCount]="500">
					<textarea
						nz-input
						[ngModel]="text()"
						(change)="registerChange($event)"
						(paste)="registerChange($event)"
						(keyup)="registerChange($event)"
						class="form-input
				{{ size() }}
				{{ horizontal() ? 'form-input--horizontal' : '' }}"
						[placeholder]="placeholder() | transloco"
						[rows]="rows()"
					></textarea>
				</nz-textarea-count>
			</nz-form-control>
		</nz-form-item>
	`,

	imports: [
		NzFormLabelComponent,
		TranslocoPipe,
		NzTextareaCountComponent,
		NzFormItemComponent,
		NzInputModule,
		FormsModule,
		NzFormControlComponent,
		JsonPipe,
	],
})
export class TextareaModelComponent {
	text = model<string>('');

	label = input<string>();
	translate = input<'false' | 'true' | boolean>(true);
	size = input<string>();
	description = input<string>('');
	horizontal = input<'false' | 'true' | boolean>();
	placeholder = input<string>();
	rows = input<number, string | number>(3, { transform: (value) => +value });

	registerChange($event: Event) {
		const htmlTextAreaElement = $event.target as HTMLTextAreaElement;
		this.text.set(htmlTextAreaElement.value);
	}
}
