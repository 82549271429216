import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-html',
	standalone: true,
	template: `
		<div class="border-t" [class.my-5]="!props['size'] || props['size'] === 'normal'" [class.my-10]="props['size'] === 'large'"></div>
	`,
})
export class DividerComponent extends FieldType<FieldTypeConfig> {}
