import { IControl } from 'mapbox-gl';

export class MapboxCustomControlWrapper implements IControl {
	private container: HTMLElement;

	constructor(componentElement: HTMLElement) {
		this.container = document.createElement('div');
		// why?
		// this.container.classList.add('mapboxgl-ctrl', 'mapboxgl-ctrl-group');
		this.container.classList.add('mapboxgl-ctrl');
		this.container.appendChild(componentElement);
	}

	onAdd(map: mapboxgl.Map): HTMLElement {
		return this.container;
	}

	onRemove(map: mapboxgl.Map): void {}
}
