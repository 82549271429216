import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-header',
	template: '<header><ng-content /></header>',
	styles: [
		`
			:host {
				@apply border-b border-gray-600 min-h-8 w-full z-10 flex-row-between bg-white;
			}
		`,
		`
			header {
				@apply flex-row-between max-md:px-2 mx-auto
				w-full md:max-w-[504px] lg:max-w-[1032px] xl:max-w-[1560px];
			}
		`,
	],
})
export class HeaderComponent {}
