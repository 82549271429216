import { NgModule } from '@angular/core';

import { I18nModule } from '@agdir/i18n/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxMapboxGlGeocoderControlModule } from 'ngx-mapbox-gl-geocoder-control';
import { FindMeModule } from '../find-me/find-me.module';

import { AgdirIconComponent } from '@agdir/agdir-icons';
import { BadgeComponent } from '@agdir/ui/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DetectFieldsControlDirective } from '../digifarms/detect-fields/mapbox-detect-fields-control.directive';
import { DigifarmModule } from '../digifarms/digifarms.module';
import { MapFullscreenDirective } from '../digifarms/fullscreen/map-fullscreen.directive';
import { ZoningLayerControlDirective } from '../digifarms/zoning-layer/zoning-layer-control.directive';
import { MapboxDrawControlComponent } from './controls/draw/mapbox-draw-control.component';
import { MapboxDrawControlDirective } from './controls/draw/mapbox-draw-control.directive';
import { GeolocateControlDirective } from './controls/geolocate-control.directive';
import { MapboxMergeControlComponent } from './controls/merge/mapbox-merge-control.component';
import { MapboxMergeControlDirective } from './controls/merge/mapbox-merge-control.directive';
import { MapboxSplitControlComponent } from './controls/split/mapbox-split-control.component';
import { MapboxSplitControlDirective } from './controls/split/mapbox-split-control.directive';
import { MapboxCheckboxComponent } from './controls/zone-filter/zone-filter.component';
import { CheckboxControlDirective } from './controls/zone-filter/zone-filter.directive';
import { MapPolygonComponent } from './map-polygon.component';
import { AgdirMapComponent } from './map.component';

@NgModule({
	declarations: [
		AgdirMapComponent,
		GeolocateControlDirective,
		MapboxDrawControlDirective,
		DetectFieldsControlDirective,
		MapboxDrawControlComponent,
		MapboxSplitControlDirective,
		MapboxSplitControlComponent,
		MapboxMergeControlDirective,
		MapboxMergeControlComponent,
		CheckboxControlDirective,
		MapPolygonComponent,
		// FieldColorDirective,
	],
	exports: [
		AgdirMapComponent,
		// FieldColorDirective,
		MapPolygonComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		MatButtonModule,

		I18nModule,
		ReactiveFormsModule,
		FindMeModule,
		DigifarmModule,
		MatFormFieldModule,
		NgxMapboxGLModule,
		NgxMapboxGlGeocoderControlModule,
		ZoningLayerControlDirective,
		MapFullscreenDirective,
		MapboxCheckboxComponent,
		AgdirIconComponent,
		MatDialogModule,
		BadgeComponent,
	],
})
export class MapModule {}
