import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-textarea',
	template: `
		<nz-form-item>
			@if (props.label && props.size == 'normal') {
				<nz-form-label [nzFor]="id" [nzRequired]="props.required">{{ props.label }}</nz-form-label>
			}
			@if (props.label && props.size == 'large') {
				<div class="text-3xl md:text-4xl font-bold">{{ props.label }}</div>
			}
			@if (props.description && props.size == 'large') {
				<div class="text-xl flex-1 font-light mb-5">{{ props.description }}</div>
			}
			<nz-form-control [nzErrorTip]="errorTpl" [nzValidateStatus]="formControl.invalid ? 'error' : ''">
				<textarea
					nz-input
					[nzSize]="props.size"
					[nzAutosize]="{ minRows: props.minRows, maxRows: props.maxRows }"
					[formControl]="formControl"
					[formlyAttributes]="field"
					[placeholder]="props.placeholder || ''"
					[disabled]="props.disabled"
					[readonly]="props.readonly"
					(blur)="props.blur && props.blur(field, formControl)"
					(focus)="props.focus && props.focus(field, formControl)"
				></textarea>
				<ng-template #errorTpl let-control>
					<formly-validation-message [field]="field"></formly-validation-message>
				</ng-template>
			</nz-form-control>
		</nz-form-item>
	`,
	imports: [NzFormModule, NzInputModule, FormlyModule, ReactiveFormsModule],
})
export class AgdirFormlyTextareaComponent extends FieldType<FieldTypeConfig> {}
