import { NgModule } from '@angular/core';

import { AgdirPageFooterComponent } from './page/agdir-page-footer.component';
import { PageContentComponent } from './page/page-content.component';
import { PageHeaderComponent } from './page/page-header.component';
import { PageComponent } from './page/page.component';

@NgModule({
	imports: [PageHeaderComponent, PageContentComponent, PageComponent, AgdirPageFooterComponent],
	exports: [PageHeaderComponent, PageContentComponent, PageComponent, AgdirPageFooterComponent],
})
export class LayoutModule {}
