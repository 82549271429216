import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'agdir-page-content',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-content></ng-content>
	`,
	styles: [
		`
			:host {
				width: 100%;
				/*grid-template-rows: min-content;*/
				grid-template-rows: max-content;
				@apply flex-1 grid grid-cols-sm md:grid-cols-md lg:grid-cols-lg xl:grid-cols-xl gap-2 md:gap-3;
			}
		`,
	],
})
export class PageContentComponent {}
