import { Location } from '@agdir/domain';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GeocodingService {
	private http = inject(HttpClient);

	fetchLocations(place: string) {
		return this.http.post<Location[]>(`/freya/geocode`, { place });
	}

	getFullGoogleInfoOnPlace(placeId: string) {
		return this.http.get<Location | null>(`/freya/geocode/${placeId}`, { params: { placeId } });
	}
}
