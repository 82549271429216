import { Company } from '@agdir/domain';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NewCompanyService } from '../new-company.service';
import { ButtonComponent } from '@agdir/ui/button';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-formly-company-registry-finder',
	template: `
		<nz-form-label [nzFor]="id" [nzRequired]="props.required">{{ props.label }}</nz-form-label>

		<nz-form-control [nzErrorTip]="errorTpl" [nzValidateStatus]="formControl.invalid ? 'error' : ''">
			<nz-input-group [nzAddOnAfter]="(props.searchDisabled | async) ? undefined : suffixIconButton">
				<input
					type="text"
					nz-input
					[nzSize]="props.size"
					[formControl]="formControl"
					[formlyAttributes]="field"
					[placeholder]="props.placeholder || ''"
					[disabled]="props.disabled || isProcessing()"
					[readonly]="props.readonly || isProcessing()"
					(keyup.enter)="searchInRegistry()"
					(blur)="props.blur && props.blur(field, formControl)"
					(focus)="props.focus && props.focus(field, formControl)"
				/>
			</nz-input-group>
			<ng-template #suffixIconButton>
				<agdir-button
					class="[&>button]:h-[28px] [&>button]:p-0"
					color="ghost"
					icon="search"
					(click)="searchInRegistry()"
					[isProcessing]="isProcessing()"
				/>
			</ng-template>

			<ng-template #errorTpl let-control>
				<formly-validation-message [field]="field"></formly-validation-message>
			</ng-template>
		</nz-form-control>
	`,
	imports: [NzFormModule, NzInputModule, NzInputModule, AsyncPipe, FormlyModule, ReactiveFormsModule, ButtonComponent],
})
export class AgdirFormlyCompanyRegistryFinderComponent extends FieldType<FieldTypeConfig<any>> {
	newCompanyService = inject(NewCompanyService);

	isProcessing = signal(false);

	async searchInRegistry() {
		this.isProcessing.set(true);

		try {
			const foundCompany = await this.newCompanyService.findByNumber({
				countryOfOrigin: this.field.model.countryOfOrigin,
				organizationNr: this.field.model.organizationNr,
			} as Company);

			this.props?.onComanySearchResult(foundCompany);
		} finally {
			this.isProcessing.set(false);
		}
	}
}
