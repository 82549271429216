import { Location, LocationType } from '@agdir/domain';
import { CompanyService, LocationsService } from '@agdir/services';
import { AsyncPipe, NgForOf } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, signal, viewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
	BehaviorSubject,
	combineLatest,
	firstValueFrom,
	map,
	merge,
	Observable,
	shareReplay,
	startWith,
	Subject,
	switchMap,
	withLatestFrom,
} from 'rxjs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { CropTypeMultiSelectComponent } from '../components/crop-type-multi-select.component';
import { ButtonComponent } from '@agdir/ui/button';
import { RouterLink } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Classifier } from '@agdir/classifiers';
import { AgdirAlertComponent } from '@agdir/alert';
import { toObservable } from '@angular/core/rxjs-interop';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		MatFormFieldModule,
		MatSelectModule,
		AsyncPipe,
		NgForOf,
		NzFormModule,
		NzGridModule,
		NzSelectModule,
		ReactiveFormsModule,
		TranslocoPipe,
		CropTypeMultiSelectComponent,
		ButtonComponent,
		RouterLink,
		NzIconModule,
		TranslocoDirective,
		AgdirAlertComponent,
		AgdirIconComponent,
	],
	selector: 'agdir-locations',
	standalone: true,
	styles: [
		`
			table {
				@apply mx-auto;
			}
		`,
		`
			th {
				@apply text-left;
			}
		`,
		`
			td,
			th {
				padding: 0.2rem 0.5rem;
				@apply text-lg;
			}
		`,
	],
	template: `
		@if (props.label && props.size == 'normal') {
			<nz-form-label>{{ props.label }}</nz-form-label>
		}
		@if (props.label && props.size == 'large') {
			<div class="text-3xl md:text-4xl font-bold">{{ props.label }}</div>
		}
		@if (props.description && props.size == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ props.description }}</div>
		}

		@if (props.showCropType) {
			<agdir-crop-type-multi-select [selected]="selectedCrops" class="flex flex-1 w-full border-b border-t py-3 my-3" />
		}

		<div class="flex items-center justify-start mb-5 flex-wrap gap-5">
			<span [innerHtml]="linkToManagement() | transloco: { companyId: currentCompanyId$ | async }"></span>
			<agdir-button color="ghost" icon="redo" (click)="reloadLocations.next()" size="small" />
		</div>

		@if (cropTypeFilterStats | async; as stats) {
			@if (stats.unmatched > 0) {
				<agdir-alert
					type="warning"
					class="mb-3"
					showAction="true"
					actionLabel="modules.locationsPage.cropTypeSelector.clearFilter"
					(actionTriggered)="this.selectedCrops.set([])"
					title="	{{ 'modules.locationsPage.cropTypeSelector.locationsFilteredByCrops' | transloco: stats }}"
				/>
			}
		}

		<table class="w-full">
			<thead>
				<tr>
					<th>
						<input type="checkbox" (change)="toggleAll($any($event.target).checked)" />
					</th>
					<th [transloco]="props['labels'].tableHeaderField"></th>
					@if (props.showCropType) {
						<th [transloco]="props['labels'].tableHeaderCrops"></th>
					}
					@if (props.showProximityToWater) {
						<th [transloco]="props['labels'].tableHeaderProximityToWater"></th>
					}
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of items$ | async" class="border-b">
					<td>
						<input type="checkbox" [checked]="isSelected(item._id)" (change)="toggleSelection(item._id, $any($event.target).checked)" />
					</td>
					<td>{{ item.name }}</td>
					@if (props.showCropType) {
						<td>{{ item.cropType }}</td>
					}
					@if (props.showProximityToWater) {
						<td>
							@if (item.proximityToWater?.isNearWater === true) {
								{{ 'general.yesText' | transloco }}
							} @else if (item.proximityToWater?.isNearWater === false) {
								{{ 'general.noText' | transloco }}
							} @else {
								<agdir-icon icon="error" style="color: orange;" />
							}
						</td>
					}
					<td>
						<a [attr.href]="'/' + item.companyId + '/field/' + item._id" target="_blank">
							<agdir-icon icon="edit" />
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	`,
})
export class LocationsFieldTypeComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
	selectedCrops = signal<Classifier[]>([]);
	selectedCropChanges = toObservable(this.selectedCrops);

	cropTypeMultiSelect = viewChild(CropTypeMultiSelectComponent);
	linkToManagement = signal('');
	locationTypes = new BehaviorSubject<LocationType[]>([]);
	locationsService = inject(LocationsService);
	allItems = this.locationTypes.pipe(
		withLatestFrom(this.locationsService.getAllLocations()),
		map(([locationTypes, locations]) =>
			locations.filter((location) => !locationTypes.length || (location.locationType && locationTypes.includes(location.locationType))),
		),
		shareReplay(1),
	);
	reloadLocations = new Subject<void>();
	items$: Observable<Location[]> = merge(this.reloadLocations.pipe(switchMap(() => this.selectedCropChanges)), this.selectedCropChanges).pipe(
		startWith(this.selectedCrops()),
		switchMap((crops) =>
			this.allItems.pipe(
				map((locations) => this.filterByCrop(locations, crops)),
				map((locations) => locations.sort((a, b) => a.name?.localeCompare(b?.name))),
			),
		),
		map((s) => {
			if (!this.props['emptyOption']) {
				return s;
			}
			return s;
		}),
		shareReplay(1),
	);
	cropTypeFilterStats = combineLatest([this.allItems, this.items$]).pipe(
		map(([allItems, items]) => ({
			total: allItems.length,
			matched: items.length,
			unmatched: allItems.length - items.length,
		})),
	);

	async ngAfterViewInit(): Promise<void> {
		this.locationTypes.next(this.props['locationTypes'] || []);
		const locations = (this.formControl.value || []).filter((location: Location) => !!location?._id);
		// const selectedCropTypes = locations.map((location: Location) => location.cropType);
		locations.forEach((location: Location) => {
			if (location.cropType) {
				this.cropTypeMultiSelect()?.addCropByCode(location.cropType, { emit: false });
			}
			this.selectedItems.add(String(location._id));
		});
		if (this.props?.['linkToManagement']) {
			this.linkToManagement.set(this.props['linkToManagement']);
		}
	}

	currentCompanyId$: Observable<unknown> = inject(CompanyService)
		.getCurrentCompany()
		.pipe(map((s) => s?._id));
	selectedItems = new Set<string>();

	private filterByCrop(locations: Location[], crops: Classifier[]): Location[] {
		return locations.filter((location) => crops?.length === 0 || crops?.some((crop) => crop.matchesCode(location.cropType, { recursive: true })));
	}

	async toggleSelection(locationId: string, isChecked: boolean): Promise<void> {
		if (isChecked) {
			this.selectedItems.add(locationId);
		} else {
			this.selectedItems.delete(locationId);
		}
		await this.updateFormControlWithSelectedItems();
	}

	async toggleAll(isChecked: boolean): Promise<void> {
		if (!this.items$) {
			return;
		}
		const locations = await firstValueFrom(this.items$);
		this.selectedItems.clear();
		if (isChecked) {
			locations.forEach((location) => this.selectedItems.add(String(location._id)));
		}
		await this.updateFormControlWithSelectedItems();
	}

	isSelected(locationId: string): boolean {
		return this.selectedItems.has(locationId);
	}

	private async updateFormControlWithSelectedItems() {
		const selectedIds = [...this.selectedItems];
		const allItems = await firstValueFrom(this.allItems);
		this.formControl.setValue(allItems.filter((item) => selectedIds.includes(String(item._id))));
		this.formControl.markAsDirty();
	}
}
