import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { WeatherEntryComponent } from '../components/weather-entry.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	selector: 'agdir-weather-condition',
	template: `
		<agdir-weather-entry [control]="formControl"></agdir-weather-entry>
	`,
	imports: [WeatherEntryComponent],
})
export class AgdirWeatherConditionComponent extends FieldType<FieldTypeConfig> {}
