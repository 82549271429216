import { PhoneNumberComponent } from '@agdir/agdir-forms';
import { Company, CompanyAccessLevel, LocationAddress } from '@agdir/domain';
import { I18nModule } from '@agdir/i18n/angular';
import { ButtonComponent } from '@agdir/ui/button';
import { AgdirColors } from '@agdir/ui/colors';
import { Component, computed, inject, input, output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { FormlyModule } from '@ngx-formly/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { firstValueFrom } from 'rxjs';
import { NewCompanyPageComponent } from '../new-company-page.component';
import { NewCompanyService } from '../new-company.service';
import { InvitationWithProgress, InviteCustomerButtonComponent } from '../../invitations';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	standalone: true,
	selector: 'agdir-company-builder-invitations',
	providers: [provideTranslocoScope('newCompany')],
	template: `
		@if (!showInvite()) {
			<div class="flex flex-col sm:flex-row items-center justify-center my-5 gap-3">
				<agdir-button color="primary" (click)="addNew()" [icon]="'person_add'" [label]="'newCompany.step4.inviteFirstPerson'" />
				<agdir-button color="primary" [label]="'newCompany.step4.inviteSkip'" (click)="nextStep()" />
			</div>
		} @else {
			<h2 class="text-lg font-semibold mb-2">Phone number invite</h2>
			@for (invitation of phoneInvitations(); track $index) {
				<div class="flex items-center mb-2 gap-2">
					<agdir-phone-number class="flex-1" [(model)]="invitation.invitee.phoneNumber" (modelChange)="store()" />
					<div class="relative flex items-center">
						<agdir-button
							class="absolute -left-10 [&>button]:h-[28px] [&>button]:p-0"
							[color]="!$last || phoneInvitations().length > 1 ? 'red' : 'ghost'"
							[disabled]="$last && phoneInvitations().length < 2"
							icon="delete"
							(click)="newCompanyService.removeInvitation(invitation.invitee)"
						/>
						@if ($last) {
							<div
								class="bg-blue-50 border border-gray-200 w-8 h-8 rounded-full flex items-center justify-center cursor-pointer hover:shadow-md"
								(click)="addNewPhone()"
							>
								<agdir-icon [icon]="'add'" />
							</div>
						} @else {
							<div class="bg-[#EAF5E4] w-8 h-8 rounded-full flex items-center justify-center ">
								<agdir-icon [icon]="'check'" />
							</div>
						}
					</div>
				</div>
			}

			<h2 class="text-lg font-semibold mt-10 mb-2">Email invite</h2>
			@for (invitation of emailInvitations(); track $index) {
				<div class="flex items-center mb-2 gap-2">
					<nz-form-control>
						<input type="text" nz-input [(ngModel)]="invitation.invitee.email" (ngModelChange)="store()" />
					</nz-form-control>
					<div class="relative flex items-center">
						<agdir-button
							class="z-10 absolute -left-10 [&>button]:h-[28px] [&>button]:p-0"
							[color]="!$last || emailInvitations().length > 1 ? 'red' : 'ghost'"
							[disabled]="$last && emailInvitations().length < 2"
							icon="delete"
							(click)="newCompanyService.removeInvitation(invitation.invitee)"
						/>
						@if ($last) {
							<div
								class="bg-blue-50 border border-gray-200 w-8 h-8 rounded-full flex items-center justify-center cursor-pointer hover:shadow-md"
								(click)="addNewEmail()"
							>
								<agdir-icon [icon]="'add'" />
							</div>
						} @else {
							<div class="bg-[#EAF5E4] w-8 h-8 rounded-full flex items-center justify-center ">
								<agdir-icon [icon]="'check'" />
							</div>
						}
					</div>
				</div>
			}
		}
	`,
	imports: [
		I18nModule,
		FormlyModule,
		FormsModule,
		NzInputModule,
		ReactiveFormsModule,
		InviteCustomerButtonComponent,
		PhoneNumberComponent,
		ButtonComponent,
		NzIconModule,
		NzFormModule,
		NzAlertModule,
		AgdirIconComponent,
	],
})
export class CompanyBuilderInvitationsComponents {
	readonly newCompanyService = inject(NewCompanyService);
	private readonly newCompanyPageComponent = inject(NewCompanyPageComponent);
	private readonly router = inject(Router);
	private readonly route = inject(ActivatedRoute);
	protected readonly AgdirColors = AgdirColors;
	protected readonly CompanyAccessLevel = CompanyAccessLevel;

	modelUpdated = output<LocationAddress>();
	company = input<Company | undefined>(undefined);

	invitations = this.newCompanyService.companyBuilder.invitations;
	phoneInvitations = computed(() => this.invitations().filter((inv) => inv.sendSms));
	emailInvitations = computed(() => this.invitations().filter((inv) => inv.sendEmail));
	showInvite = computed(() => this.invitations().length > 0);

	addNewPhone() {
		this.newCompanyService.companyBuilder.addInvitation({
			permission: CompanyAccessLevel.CRUD,
			phoneNumberValidated: null,
			forceCreateInvitee: false,
			sendSms: true,
			sendEmail: false,
			acceptInvitation: false,
			invitee: {},
		} as InvitationWithProgress);
	}

	addNewEmail() {
		this.newCompanyService.companyBuilder.addInvitation({
			permission: CompanyAccessLevel.CRUD,
			phoneNumberValidated: null,
			forceCreateInvitee: false,
			sendSms: false,
			sendEmail: true,
			acceptInvitation: false,
			invitee: {},
		} as InvitationWithProgress);
	}

	addNew() {
		this.addNewEmail();
		this.addNewPhone();
	}

	store() {
		this.newCompanyService.companyBuilder.storeInLocalStorage();
	}

	async nextStep() {
		const curr = await firstValueFrom(this.newCompanyPageComponent.currentView);
		this.router.navigate(['..', `${curr.next?.path}`], { relativeTo: this.route });
	}
}
