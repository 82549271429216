import { I18nModule } from '@agdir/i18n/angular';
import { AsyncPipe, JsonPipe, NgClass, NgFor } from '@angular/common';
import { Component, computed, HostBinding, input, OnChanges, output, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { derivedAsync } from 'ngxtension/derived-async';
import { startWith } from 'rxjs';
import { AgdirSelectolor, AgdirSelectOption, selectColorMapSet, SelectSizeType } from './model-components/agdir-select-model.component';

@Component({
	standalone: true,
	selector: 'agdir-select',
	template: `
		@if (label() && size() == 'normal') {
			<nz-form-label [class.leading-none]="horizontal()">{{ label() | transloco }}</nz-form-label>
		}
		@if (label() && size() == 'large') {
			<div class="text-3xl md:text-4xl font-bold" [class.leading-none]="horizontal()">{{ label() | transloco }}</div>
		}
		@if (label() && size() == 'medium') {
			<div class="text-xl font-light" [class.leading-none]="horizontal()">{{ label() | transloco }}</div>
		}
		@if (description() && size() == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ description() | transloco }}</div>
		}
		<nz-select
			[nzDisabled]="disabled() === 'true' || disabled() === true"
			[ngModel]="selectedValue()"
			[class.ml-2]="horizontal() === 'true' || horizontal() === true"
			(ngModelChange)="onChanged($event)"
			[nzPlaceHolder]="placeholder()"
			[nzMaxTagCount]="0"
			[nzMaxTagPlaceholder]="multiSelectPlaceholder"
			[nzShowArrow]="true"
			[nzDropdownMatchSelectWidth]="false"
			[nzLoading]="isProcessing()"
			[nzSize]="selectSize()"
			[nzMode]="multi() ? 'multiple' : 'default'"
			[nzCustomTemplate]="defaultTemplate"
			[nzOptionHeightPx]="optionHeight()"
			[ngClass]="selectClass()"
			class="w-full"
		>
			<nz-option *ngFor="let fo of options()" [nzCustomContent]="fo.description" [nzValue]="fo.value" [nzLabel]="fo.label | transloco">
				<div>{{ fo.label | transloco }}</div>
				<div class="text-xs text-gray-800">{{ fo.description | transloco }}</div>
			</nz-option>
		</nz-select>
		<ng-template #defaultTemplate let-selected>
			{{ valuePrefix() | transloco }}
			{{ selected.nzLabel }}
		</ng-template>
		<ng-template #multiSelectPlaceholder let-selectedList>
			<div class="flex items-center gap-2">
				<span>{{ placeholder() }}</span>
				<nz-badge [nzCount]="selectedList.length" [nzStyle]="{ backgroundColor: '#8bb8f8' }"></nz-badge>
			</div>
		</ng-template>
	`,
	styles: [
		`
			:host {
				@apply flex justify-start flex-col;
			}
		`,
		`
			:host.pill ::ng-deep > nz-select > nz-select-top-control {
				/*@apply !rounded-full !pl-2 !pr-1 !py-1 !h-[28px];*/
			}

			:host ::ng-deep > nz-select .ant-select-selection-placeholder {
				transform: none;
				@apply relative inset-0;
			}
		`,
	],
	imports: [
		NzButtonModule,
		I18nModule,
		NgFor,
		AsyncPipe,
		NgClass,
		NzIconModule,
		FormsModule,
		NzSelectModule,
		ReactiveFormsModule,
		NzBadgeModule,
		NzFormModule,
		JsonPipe,
		NzGridModule,
	],
})
export class AgdirSelectComponent implements OnChanges {
	changed = output<AgdirSelectOption>();

	@HostBinding('class.flex-row') @HostBinding('class.items-center') classHorizontal = false;
	horizontal = input<'false' | 'true' | boolean>();

	optionHeight = input<number>(32);
	size = input<'normal' | 'large' | 'medium' | 'default'>('default');
	selectSize = input<SelectSizeType>('default');
	options = input<AgdirSelectOption[]>([]);
	selectControl = input<FormControl<any>>(new FormControl<any>({}));
	multi = input<boolean>(false);
	value = input<AgdirSelectOption | AgdirSelectOption[] | null | undefined>();
	label = input<string>('');
	description = input<string>('');
	valuePrefix = input<string>('');
	placeholder = input<string>('');
	disabled = input<'true' | 'false' | boolean>(false);
	color = input<AgdirSelectolor>('default');
	isProcessing = input<boolean>(false);

	selectControlValue = derivedAsync(() => this.selectControl().valueChanges.pipe(startWith(this.selectControl().value)));
	selectClass = computed(() => selectColorMapSet.get(this.color()));
	selectedValue = computed(() => this.getInputValue() || this.selectControlValue());
	getInputValue = computed<string | string[]>(() => {
		if (!this.multi()) {
			return (this.value() as AgdirSelectOption)?.value;
		}
		return (this.value() as AgdirSelectOption[])?.map((v) => v.value) || [];
	});

	constructor() {
		computed(() => {
			this.classHorizontal = this.horizontal() === 'true';
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['value']) {
			this.selectControl().setValue(changes['value'].currentValue?.value);
		}
	}

	onChanged(value: any) {
		const option = this.findOption(value);
		this.selectControl().setValue(this.getOptionValue(option));
		this.selectControl().markAsDirty();
		this.changed.emit(option as any);
	}

	private findOption(value: any): AgdirSelectOption | AgdirSelectOption[] {
		if (!this.multi()) {
			return this.options()?.find((s) => s.value === value) as AgdirSelectOption;
		}
		return this.options()?.filter((s) => value.includes(s.value)) || [];
	}

	private getOptionValue(option: AgdirSelectOption | AgdirSelectOption[]): string | string[] {
		if (!this.multi()) {
			return (option as AgdirSelectOption).value;
		}
		return (option as AgdirSelectOption[]).map((v) => v.value) || [];
	}
}
