import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { AgdirSelectOption } from '@agdir/agdir-forms';
import { ButtonComponent, ButtonSizeType } from '@agdir/ui/button';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TranslocoPipe, NzFormModule, ButtonComponent],
	selector: 'agdir-radio-buttons',
	standalone: true,

	template: `
		@if (label() && size() == 'default') {
			<nz-form-label>{{ label() | transloco }}</nz-form-label>
		}
		@if (label() && size() == 'large') {
			<div class="text-3xl md:text-4xl font-bold">{{ label() | transloco }}</div>
		}
		@if (description() && size() == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ description() | transloco }}</div>
		}
		<div class="flex flex-row flex-wrap gap-3">
			@for (option of options(); track 'value'; let i = $index) {
				<agdir-button
					[size]="size()"
					[color]="option.selected ? 'secondary' : 'ghost'"
					[icon]="option.selected ? 'check_circle' : ''"
					(click)="onSelectionChange(option)"
					[label]="option.label"
				/>
			}
		</div>
	`,
})
export class AgdirRadioButtonsComponent {
	label = input('');
	description = input('');
	size = input<ButtonSizeType>('default');
	options = input<AgdirSelectOption[]>([]);
	changed = output<AgdirSelectOption>();

	onSelectionChange(option: AgdirSelectOption): void {
		this.options().forEach((o) => (o.selected = o.value === option.value));
		this.changed.emit(option);
	}
}
