import { AgdirIconComponent } from '@agdir/agdir-icons';
import { AssetsStorageKey, AssetsStorageService, CompanyService, CUSTOMER_PATHS } from '@agdir/services';
import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective, TranslocoPipe } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { hostBinding } from 'ngxtension/host-binding';
import { ButtonComponent } from '@agdir/ui/button';
import * as version from '../../../../../version.json';
import { NgScrollbar } from 'ngx-scrollbar';
import { AsyncPipe } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { VersionComponent } from './version/version.component';
import { BetaComponent } from './beta.component';
import { AgdirNavigationItem } from '../components/navigation.types';
import { NavigationService } from '../navigation-service/navigation.service';

function canExpandSidebar() {
	return (window.innerWidth >= 900 && window.innerWidth < 1280) || window.innerWidth >= 1380;
}

function getDefaultExpandedState() {
	return canExpandSidebar() ? (AssetsStorageService.get<boolean>(AssetsStorageKey.sidebarExpanded, true) ?? true) : false;
}

function setDefaultExpandedState(value: boolean) {
	AssetsStorageService.set(AssetsStorageKey.sidebarExpanded, value);
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'agdir-sidebar',
	template: `
		<a id="logo" href="/">
			<img alt="Logo" width="141" height="47" src="assets/logo/logo-full-white.svg" />
		</a>

		<ng-scrollbar>
			<nav>
				<a href="" (click)="toggle($event)">
					<agdir-icon [icon]="isExpanded() ? 'arrow_back' : 'arrow_forward'" size="large" />
					<span>{{ (isExpanded() ? 'general.collapseSidebar' : 'general.expandSidebar') | transloco }}</span>
				</a>

				@for (nav of baseNavigation | async; track $index) {
					<a
						routerLinkActive="active"
						[nz-tooltip]="isExpanded() ? '' : (nav.title || '' | transloco)"
						nzTooltipPlacement="right"
						[id]="'menu-' + nav.link"
						(click)="closeMobileMenu($event)"
						[routerLink]="'/' + currentCompany()?._id + '/' + nav.link"
					>
						<agdir-icon [icon]="nav.icon" [size]="null" />
						<span>
							{{ nav.title || '' | transloco }}
							@if (nav.beta) {
								<agdir-beta-component color="blue" />
							}
						</span>
					</a>
				}
			</nav>
			<agdir-version class="text-gray-400 text-center mb-2" [version]="version" />
		</ng-scrollbar>
	`,
	imports: [
		RouterLinkActive,
		RouterLink,
		VersionComponent,
		BetaComponent,
		TranslocoPipe,
		AgdirIconComponent,
		TranslocoDirective,
		ButtonComponent,
		NgScrollbar,
		AsyncPipe,
		NzTooltipDirective,
		VersionComponent,
	],
	styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
	readonly isExpanded = signal<boolean>(getDefaultExpandedState());
	// protected readonly isExpanded = signal<boolean>(window.innerWidth >= 720);
	protected readonly itDoesNotHaveUsageBecauseItDoesHostBinding = hostBinding('class.expanded', this.isExpanded);
	protected readonly baseNavigation: Observable<AgdirNavigationItem[]> = inject(NavigationService).navigation$;
	protected readonly currentCompany = inject(CompanyService).currentCompanySignal;
	protected readonly CUSTOMER_PATHS = CUSTOMER_PATHS;
	protected readonly version = version;

	constructor() {
		effect(() => setDefaultExpandedState(this.isExpanded()));
	}
	toggle(e?: Event) {
		e?.preventDefault();
		const nextState = !this.isExpanded();
		this.isExpanded.set(nextState);
	}

	protected async closeMobileMenu(event: Event): Promise<void> {
		event.preventDefault();
		if (window.innerWidth < 992) {
			this.isExpanded.set(false);
		}
	}

	open() {
		this.isExpanded.set(true);
	}

	close() {
		this.isExpanded.set(false);
	}
}
