import { Feature, Polygon } from '@turf/turf';
import { FitBoundsOptions, LngLatBounds } from 'mapbox-gl';

export interface LocationMapSettings {
	zoom: number;
	minZoom: number;
	maxZoom: number;
	bounds: LngLatBounds;
	fitBoundsOptions: FitBoundsOptions;
}

export interface LocationSplitResult {
	source: string;
	locationPolygons: Feature<Polygon>[];
}

export interface LocationMergeResult {
	source: string[];
	polygon: Feature<Polygon>;
}

export const DEFAULT_MAP_CONFIG = {
	zoom: 16,
	minZoom: 0,
	maxZoom: 22,
};

export const DEFAULT_FITBOUNDS_OPTIONS: FitBoundsOptions = {
	maxZoom: DEFAULT_MAP_CONFIG.zoom,
	padding: 50,
	duration: 800,
};

// TODO: make configurable per product country in the future?
export const DEFAULT_FITBOUND_LOCATION: { lng: number; lat: number } = {
	lng: 8.7725,
	lat: 58.4615,
};

export const calcBounds = (polygons: Polygon[]): LngLatBounds => {
	return polygons.reduce(
		(bounds, polygon) =>
			polygon.coordinates.reduce((bound, positions) => positions.reduce((b, position) => b.extend([position[0], position[1]]), bound), bounds),
		new LngLatBounds(),
	);
};

export const makeMapConfig = (geoPoints: Polygon[], settings: Partial<LocationMapSettings> = {}): LocationMapSettings => {
	return {
		...DEFAULT_MAP_CONFIG,
		fitBoundsOptions: DEFAULT_FITBOUNDS_OPTIONS,
		bounds: calcBounds(geoPoints),
		...settings,
	};
};
