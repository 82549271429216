import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, Directive, Host, output, ViewContainerRef } from '@angular/core';
import { Point } from '@turf/turf';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { FindMeComponent } from '../../find-me/find-me.component';
import { MapboxCustomControlWrapper } from './mapbox-custom-control-wrapper';

@Directive({
	selector: '[agdirGeolocate]',
})
export class GeolocateControlDirective extends BaseDirective implements AfterContentInit {
	geolocate = output<Point>();

	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$.subscribe(() => {
			const componentRef = this.viewContainerRef.createComponent(FindMeComponent);
			componentRef.instance.myPosition.subscribe((location: Point) => this.geolocate.emit(location));

			this.controlComponent.control = new MapboxCustomControlWrapper(componentRef.location.nativeElement);

			this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
		});
	}
}
