import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	standalone: true,
	selector: 'agdir-zoning-layer-control',
	template: `
		<button type="button" matRipple (click)="click()" class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5">
			<agdir-icon class="w-5" *ngIf="zonesAreShown" icon="layers_clear" />
			<agdir-icon class="w-5" *ngIf="!zonesAreShown" icon="layers" />
			<span *ngIf="zonesAreShown">Hide zoning layers</span>
			<span *ngIf="!zonesAreShown">Show zoning layers</span>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, MatRippleModule, AgdirIconComponent],
})
export class ZoningLayerControlComponent {
	zonesAreShown = false;
	clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private cd: ChangeDetectorRef) {}

	click() {
		this.zonesAreShown = !this.zonesAreShown;
		this.clicked.emit(this.zonesAreShown);
		this.cd.markForCheck();
		this.cd.detectChanges();
	}
}
