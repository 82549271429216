import { AgdirDatatableEntity } from '@agdir/datatable';

export const datatableFilterSearch = (entities: AgdirDatatableEntity[] = [], searchTerm = ''): AgdirDatatableEntity[] => {
	const search = searchTerm.toLocaleLowerCase();

	function searchNode(node: AgdirDatatableEntity): AgdirDatatableEntity | null {
		if (node.name.toLocaleLowerCase().includes(search)) {
			return node;
		}
		if (node.children) {
			const matchingChildren = node.children.map(searchNode).filter(Boolean) as AgdirDatatableEntity[];
			if (matchingChildren.length > 0) {
				return { ...node, children: matchingChildren };
			}
		}
		return null;
	}

	return entities.map(searchNode).filter(Boolean) as AgdirDatatableEntity[];
};
