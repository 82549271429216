import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, Directive, Host, input, output, ViewContainerRef } from '@angular/core';
import { FeatureCollection, Polygon } from '@turf/turf';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { takeUntil } from 'rxjs';
import { MapboxCustomControlWrapper } from '../../map/controls/mapbox-custom-control-wrapper';
import { DigifarmService } from '../digifarms.service';
import { MapboxDetectFieldsControlComponent } from './mapbox-detect-fields-control.component';

@Directive({
	selector: '[agdirDetectFields]',
})
export class DetectFieldsControlDirective extends BaseDirective implements AfterContentInit {
	fieldsDetected = output<FeatureCollection<Polygon> | null>();
	detecting = output<boolean>();
	minZoom = input(14);

	constructor(
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
		private digiFarmsService: DigifarmService,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$.subscribe(() => {
			const componentRef = this.createRef();
			const detectDetectability = async () => {
				if (this.mapService.mapInstance.getZoom() < this.minZoom()) {
					componentRef.instance.visible = false;
				} else {
					componentRef.instance.visible = await this.digiFarmsService.canDelineateFields(this.mapService.mapInstance);
				}
				componentRef.changeDetectorRef.detectChanges();
			};
			this.mapService.mapInstance.on('moveend', detectDetectability);
			this.mapService.mapInstance.on('load', detectDetectability);
			this.controlComponent.control = new MapboxCustomControlWrapper(componentRef.location.nativeElement);
			this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
		});
	}

	async loadDelineatedFields(mapInstance: mapboxgl.Map): Promise<FeatureCollection<Polygon> | null> {
		return this.digiFarmsService.getDelineatedFields(mapInstance);
	}

	private createRef() {
		const ref = this.viewContainerRef.createComponent(MapboxDetectFieldsControlComponent);
		ref.instance.detectFieldsClick.pipe(takeUntil(this.destroyed$)).subscribe(async () => {
			this.detecting.emit(true);
			ref.instance.isLoading.next(true);
			ref.changeDetectorRef.detectChanges();
			try {
				const digiFarmsResponse = await this.loadDelineatedFields(this.mapService.mapInstance);
				ref.instance.isLoading.next(false);
				this.fieldsDetected.emit(digiFarmsResponse);
				ref.changeDetectorRef.detectChanges();
			} catch {
				this.fieldsDetected.emit(null);
				this.detecting.emit(false);
				ref.changeDetectorRef.detectChanges();
			} finally {
				ref.instance.isLoading.next(false);
				this.detecting.emit(false);
				ref.changeDetectorRef.detectChanges();
			}
		});
		return ref;
	}
}
