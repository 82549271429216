import { AuthCandidateSession } from '@agdir/bifrost';
import { AgdirHttpV2Client, CompanyService } from '@agdir/services';
import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, startWith, Subject } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { AnonymousInvitation, Invitation, InvitationCode } from '../invitations';
import { toSignal } from '@angular/core/rxjs-interop';

const API = '/heimdall/invite';

@Injectable({ providedIn: 'root' })
export class InvitationService {
	reload = new Subject<void>();
	private currentCompanyInvites$ = this.reload.pipe(
		startWith(true),
		switchMap(() => this.companyService.getCurrentCompany()),
		switchMap((company) => this.httpClient.get<Invitation[]>(`${API}/company/${company._id}`)),
		shareReplay(1),
	);

	currentCompanyInvitesSignal = toSignal(this.currentCompanyInvites$);

	constructor(
		private httpClient: AgdirHttpV2Client,
		private companyService: CompanyService,
	) {}

	inviteeStatus(code: InvitationCode): Observable<{ exists: boolean }> {
		return this.httpClient.get<{ exists: boolean }>(`${API}/${code}/user-status`);
	}

	getByCode(code: InvitationCode): Observable<Invitation> {
		return this.httpClient.get<Invitation>(`${API}/${code}`).pipe(map((invitation) => this.mapDates(invitation)));
	}

	getByCodeAnonymously(code: InvitationCode): Observable<AnonymousInvitation> {
		return this.httpClient.get<AnonymousInvitation>(`${API}/${code}/anonymously`);
	}

	async decline(invitation: Invitation): Promise<boolean> {
		return firstValueFrom(this.httpClient.delete<boolean>(`${API}/${invitation.code}`));
	}

	async delete(invitation: Invitation): Promise<boolean> {
		return firstValueFrom(this.httpClient.delete<boolean>(`${API}/${invitation.code}/delete`));
	}

	async accept(invitation: Invitation): Promise<boolean> {
		return firstValueFrom(this.httpClient.patch<boolean>(`${API}/${invitation.code}`, {}));
	}

	acceptAnonymously(invitationCode: string) {
		return this.httpClient.patch<AuthCandidateSession>(`${API}/${invitationCode}/anonymously`, {});
	}

	async sendInviteToNumber(invitation: Partial<Invitation>): Promise<Invitation> {
		const company = invitation.company?._id
			? {
					_id: invitation.company._id,
					organizationName: invitation.company?.organizationName,
				}
			: await firstValueFrom(this.companyService.getCurrentCompany());
		return firstValueFrom(this.httpClient.post<Invitation>(API, { ...invitation, company }));
	}

	getForCurrentCompany(): Observable<Invitation[]> {
		return this.currentCompanyInvites$;
	}

	getForCurrentCustomer(): Observable<Invitation[]> {
		return this.httpClient
			.get<Invitation[]>(API)
			.pipe(
				map((invitations) =>
					invitations.map((invitation) => this.mapDates(invitation)).sort((a, b) => b.expiresAt.getTime() - a.expiresAt.getTime()),
				),
			);
	}

	private mapDates(invite: Invitation): Invitation {
		if (invite.changedAt) {
			invite.changedAt = new Date(invite.changedAt);
		}
		if (invite.changedAt) {
			invite.changedAt = new Date(invite.changedAt);
		}
		if (invite.expiresAt) {
			invite.expiresAt = new Date(invite.expiresAt);
		}
		return invite;
	}
}
