import { AgdirIconComponent } from '@agdir/agdir-icons';
import { AgdirFile } from '@agdir/fillagring/domain';
import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { FileService } from './file.service';
import { HumanSizePipe } from './human-size.pipe';
import { AgdirConfirmationService } from '@agdir/ui';

@Component({
	standalone: true,
	selector: 'agdir-file-list',
	template: `
		<div class="w-full flex flex-col gap-1">
			@for (file of files(); track $index) {
				<div class="flex items-center flex-row border gap-1 rounded-[3px] p-1">
					@if (file.uploadedAt) {
						<agdir-button (click)="download(file)" color="ghost" icon="download" />
					}
					<div class="flex-1">
						<div>{{ file.name }}</div>
						<!--						<div class="text-sm text-gray-500">{{ file.size | humanSize }} ({{ file.type }})</div>-->
					</div>
					@if (showDelete()) {
						<agdir-button (click)="delete(file)" icon="delete" color="red-ghost" />
					}
				</div>
			} @empty {
				<div class="flex flex-row border-b">
					<div class="flex items-center justify-center w-full gap-2">
						<agdir-icon icon="info" />
						<div transloco="media.youDontHaveFilesHere"></div>
					</div>
				</div>
			}
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent, HumanSizePipe, TranslocoDirective, AgdirIconComponent],
})
export class FileListComponent {
	files = model<AgdirFile[]>();
	showDelete = input(true);
	fileService = inject(FileService);
	genericAgdirConfirmationService = inject(AgdirConfirmationService);

	async delete(file: AgdirFile) {
		if (await this.genericAgdirConfirmationService.genericDelete('Are you sure you want to delete this file?')) {
			file.softDeleted = true;
			this.files.update((files) => files?.filter((file) => !file.softDeleted) || []);
		}
	}

	async download(file: AgdirFile) {
		const url = await this.fileService.download(file.path);
		window.open(url, '_blank');
	}
}
