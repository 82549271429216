import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TranslocoPipe } from '@ngneat/transloco';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { AgdirRadioButtonsComponent } from '../components/agdir-radio-buttons.component';
import { AgdirCheckboxesComponent } from '../components/agdir-checkboxes.component';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TranslocoPipe, NzFormModule, NzGridModule, AgdirRadioButtonsComponent, AgdirCheckboxesComponent],
	selector: 'agdir-formly-button-toggle',
	standalone: true,
	styles: [
		`
			ul {
				@apply flex flex-row flex-wrap gap-5;
			}
		`,
	],
	template: `
		@if (props['type'] === 'radio') {
			<agdir-radio-buttons
				[label]="props['label']"
				[description]="props['description']"
				[size]="props['size']"
				[options]="$any(props['options']) || []"
				(changed)="formControl.setValue($event.value)"
			/>
		} @else {
			<agdir-checkbox
				[label]="props['label']"
				[description]="props['description']"
				[size]="props['size']"
				[options]="$any(props['options']) || []"
				(changed)="formControl.setValue($event)"
			/>
		}
	`,
})
export class FormlyButtonToggleComponent extends FieldType<FieldTypeConfig> implements OnInit {
	ngOnInit(): void {
		if (this.props['type'] === 'radio') {
			this.props['options']?.forEach((option) => {
				option.selected = option.value === this.formControl.value;
			});
		} else {
			this.props['options']?.forEach((option) => {
				option.selected = this.formControl.value?.includes(option.value);
			});
		}
	}
}
