import { ChangeDetectionStrategy, Component, input, model } from '@angular/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { AgdirSelectOption } from '@agdir/agdir-forms';
import { ButtonComponent, ButtonSizeType } from '@agdir/ui/button';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TranslocoPipe, NzFormModule, ButtonComponent],
	selector: 'agdir-radio-buttons-model',
	standalone: true,
	template: `
		@if (label() && size() == 'default') {
			<nz-form-label>{{ label() | transloco }}</nz-form-label>
		}
		@if (label() && size() == 'large') {
			<div class="text-3xl md:text-4xl font-bold">{{ label() | transloco }}</div>
		}
		@if (description && size() == 'large') {
			<div class="text-xl flex-1 font-light mb-5">{{ description() | transloco }}</div>
		}
		<div class="flex flex-row flex-wrap gap-3">
			@for (option of options(); track 'value'; let i = $index) {
				<agdir-button
					[size]="size()"
					[color]="value() === option.value ? 'secondary' : 'ghost'"
					[icon]="value() === option.value ? 'check_circle' : ''"
					(click)="setValue(option)"
					[label]="option.label"
				/>
			}
		</div>
	`,
})
export class AgdirRadioButtonsModelComponent {
	value = model<any | null>(null);
	label = input<string>('');
	validator = input<((option: AgdirSelectOption) => Promise<boolean>) | null>(null);
	description = input<string>('');
	size = input<ButtonSizeType>('default');
	options = input<AgdirSelectOption[]>([]);

	async setValue(option: AgdirSelectOption) {
		const validator = this.validator();
		if (validator ? await validator(option) : true) {
			this.value.set(option.value);
		}
	}
}
