import { BaseDirective } from '@agdir/core/angular';
import { AfterContentInit, ComponentRef, Directive, Host, input, NgZone, output, ViewContainerRef } from '@angular/core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ControlComponent, MapService } from 'ngx-mapbox-gl';
import { first, takeUntil } from 'rxjs';
import { AgdirMapComponent } from '../../map.component';
import { MapboxCustomControlWrapper } from '../mapbox-custom-control-wrapper';
import { MapboxDrawControlComponent } from './mapbox-draw-control.component';

@Directive({
	selector: '[agdirDraw]',
})
export class MapboxDrawControlDirective extends BaseDirective implements AfterContentInit {
	editMode = input(false);
	toggleDraw = output<boolean>();
	locationDrawn = output<MapboxDraw.DrawCreateEvent>();
	locationUpdated = output<MapboxDraw.DrawUpdateEvent>();

	drawEnabled = false;
	drawControlComponentRef?: ComponentRef<MapboxDrawControlComponent>;

	mapDraw = new MapboxDraw({
		touchEnabled: true,
		displayControlsDefault: false,
	});

	constructor(
		@Host() private controlComponent: ControlComponent<MapboxCustomControlWrapper>,
		private viewContainerRef: ViewContainerRef,
		private mapService: MapService,
		private agdirMapCmp: AgdirMapComponent,
		private zone: NgZone,
	) {
		super();
	}

	ngAfterContentInit(): void {
		this.mapService.mapCreated$.subscribe(() => {
			this.mapService.mapInstance.addControl(this.mapDraw);

			if (this.editMode()) {
				this.mapService.mapInstance.on('draw.update', (e) => this.onMapBoxUpdate(e));

				this.agdirMapCmp.locations$.pipe(first(), takeUntil(this.destroyed$)).subscribe((locations) => {
					this.mapDraw.deleteAll();
					locations.forEach((l) => l.geoJson && this.mapDraw.add(l.geoJson));

					const allFeatures = this.mapDraw.getAll();

					allFeatures.features.forEach((feature) => {
						if (feature.geometry.type === 'Polygon') {
							this.mapDraw.changeMode('direct_select', { featureId: feature.id as string });
						}
					});
				});
			} else {
				this.mapService.mapInstance.on('draw.create', (e) => this.onMapBoxDraw(e));

				this.drawControlComponentRef = this.viewContainerRef.createComponent(MapboxDrawControlComponent);

				this.drawControlComponentRef.instance.onDrawBtnClick.pipe(takeUntil(this.destroyed$)).subscribe(() => {
					this.toggleDrawMode();
				});

				this.controlComponent.control = new MapboxCustomControlWrapper(this.drawControlComponentRef.location.nativeElement);
				this.mapService.addControl(this.controlComponent.control, this.controlComponent.position);
			}
		});
	}

	onMapBoxDraw(event: MapboxDraw.DrawCreateEvent) {
		this.zone.run(() => {
			this.locationDrawn.emit(event);
			this.toggleDrawMode();
		});
	}

	onMapBoxUpdate(event: MapboxDraw.DrawUpdateEvent) {
		this.zone.run(() => {
			this.locationUpdated.emit(event);
		});
	}

	toggleDrawMode() {
		this.drawEnabled = !this.drawEnabled;
		this.agdirMapCmp.drawingMode.set(this.drawEnabled);

		if (this.drawEnabled) {
			this.mapDraw.changeMode('draw_polygon');
		} else {
			this.mapDraw.trash();
		}

		if (this.drawControlComponentRef) {
			this.drawControlComponentRef.instance.drawEnabled = this.drawEnabled;
			this.drawControlComponentRef.changeDetectorRef.detectChanges();
		}

		this.toggleDraw.emit(this.drawEnabled);
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.mapDraw.deleteAll();
		this.mapService.mapInstance.removeControl(this.mapDraw);
	}
}
