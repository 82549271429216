import { Component, input } from '@angular/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	standalone: true,
	selector: 'agdir-label',
	template: `
		@if (icon()) {
			<agdir-icon class="icon-size-9 my-2 mr-4" [icon]="icon()" />
			<ng-content></ng-content>
		}
	`,
	styles: [
		`
			:host {
				position: relative;
				margin-top: 0;
				-webkit-backface-visibility: hidden;
				backface-visibility: hidden;
				transition: none;
				font-weight: 500;
				--tw-text-opacity: 1 !important;
				color: rgba(var(--fuse-text-default-rgb), var(--tw-text-opacity)) !important;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		`,
	],
	imports: [AgdirIconComponent],
})
export class LabelComponent {
	icon = input<string>();
}
