import { AgdirIconComponent } from '@agdir/agdir-icons';
import { AgdirCardComponent } from '@agdir/core/angular';
import { ButtonComponent } from '@agdir/ui/button';
import { AgdirColors } from '@agdir/ui/colors';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { sub } from 'date-fns';
import { BetaComponent } from '../sidebar/beta.component';

@Component({
	standalone: true,
	selector: 'agdir-page-header',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<agdir-card [title]="title()" [subtitle]="subtitle()">
			<ng-container beforeTitle>
				@if (showBack() === true || showBack() === 'true') {
					<agdir-icon (click)="goBack()" icon="chevron_left" class="text-2xl" />
				}
				@if (inProgress() === true || inProgress() === 'true') {
					<agdir-spinner />
				}
				@if (!inProgress() && image()) {
					<img [src]="image()" [attr.alt]="imageAlt() || title()" class="mr-2 w-16 h-16" />
				}
				<ng-content select="[imageSection]"></ng-content>
			</ng-container>
			<ng-container menu>
				<ng-content select="[menu]"></ng-content>
			</ng-container>
			<ng-container afterTitle>
				<ng-content select="[afterTitle]"></ng-content>
			</ng-container>
			<ng-content></ng-content>

			<ng-container titlePostfix>
				@if (beta() === true || beta() === 'true') {
					<agdir-beta-component />
				}
			</ng-container>
		</agdir-card>
	`,
	styles: [
		`
			:host {
				@apply mb-3 w-full;

				agdir-card ::ng-deep h3 {
					@apply header3 lg:header2 xl:header1;
				}
			}
		`,
	],
	imports: [ButtonComponent, SpinnerComponent, TranslocoPipe, AgdirIconComponent, AgdirCardComponent, BetaComponent],
})
export class PageHeaderComponent {
	title = input<string>('');
	subtitle = input<string>('');
	icon = input<string>('');
	image = input<string>('');
	imageAlt = input<string>('');
	translate = input<boolean>(true);
	truncate = input<boolean | 'true' | 'false'>(true);
	showBack = input<boolean | 'true' | 'false'>(false);
	beta = input<boolean | 'true' | 'false'>(false);
	backBehaviour = input<'history' | string>('../');
	inProgress = input<boolean | 'true' | 'false'>(false);
	truncated = computed(() => {
		return this.truncate() === true || this.truncate() === 'true';
	});
	protected readonly AgdirColors = AgdirColors;
	protected readonly sub = sub;
	private route = inject(ActivatedRoute);
	private router = inject(Router);

	async goBack() {
		if (this.backBehaviour() === 'history') {
			return window.history.back();
		} else {
			const whereToGo = this.backBehaviour() === 'parent' ? '../' : this.backBehaviour();
			return this.router.navigate([whereToGo], { relativeTo: this.route });
		}
	}
}
