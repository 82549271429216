import { AgdirIconComponent } from '@agdir/agdir-icons';
import { ButtonComponent } from '@agdir/ui/button';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit, output } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { WebcamImage, WebcamInitError, WebcamModule } from 'ngx-webcam';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Component({
	standalone: true,
	selector: 'agdir-camera-upload',
	templateUrl: './camera-upload.component.html',
	styleUrl: './camera-upload.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, WebcamModule, AsyncPipe, SpinnerComponent, AgdirIconComponent, TranslocoDirective, ButtonComponent],
})
export class CameraUploadComponent implements OnInit {
	doNotRender = input<boolean | 'false' | 'true'>(false);
	fileAdded = output<File>();

	hasCameraPermission = true;
	showCamera = new ReplaySubject(1);
	isIdle$ = new BehaviorSubject<boolean>(true);

	windowSize = {
		width: window.innerWidth,
		height: window.innerHeight,
	};

	errors: WebcamInitError[] = [];

	private trigger: Subject<void> = new Subject<void>();
	private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

	get triggerObservable(): Observable<void> {
		return this.trigger.asObservable();
	}

	get nextWebcamObservable(): Observable<boolean | string> {
		return this.nextWebcam.asObservable();
	}

	open() {
		this.showCamera.next(true);
	}

	ngOnInit(): void {
		navigator.permissions.query({ name: 'camera' as any }).then((permissionObj) => {
			if (permissionObj.state === 'prompt') {
				permissionObj.addEventListener('change', (permissionEvent: Event) => {
					this.hasCameraPermission = (permissionEvent.target as PermissionStatus).state !== 'denied';
				});
				navigator.mediaDevices.getUserMedia({ video: true }).catch(() => (this.hasCameraPermission = false));
			} else {
				this.hasCameraPermission = permissionObj.state !== 'denied';
			}
		});
	}

	changeWebCam(directionOrDeviceId: boolean | string) {
		this.nextWebcam.next(directionOrDeviceId);
	}

	async handleImage(webcamImage: WebcamImage) {
		this.isIdle$.next(false);
		const file: File = this.dataURLtoFile(webcamImage.imageAsDataUrl, new Date().toISOString() + '.jpg');
		this.isIdle$.next(true);
		this.showCamera.next(false);
		this.fileAdded.emit(file);
	}

	private dataURLtoFile(dataurl: string, filename: string) {
		const arr = dataurl.split(',');
		const mime = (arr[0].match(/:(.*?);/) as string[])[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}
}
