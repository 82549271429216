import { inject, Injectable } from '@angular/core';
import { FormlyCustomType, FormlyStuff } from '@agdir/agdir-forms';
import { FormGroup } from '@angular/forms';
import { CompanyAccessLevel, phoneNumber, Profile } from '@agdir/domain';
import { ProfileService } from '@agdir/services';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AccessLevelToStringPipe } from '../components/access-level-to-string.pipe';

export type UpdateableProfile = Pick<Profile, 'name' | 'surname' | 'email'>;

@Injectable({ providedIn: 'root' })
export class CustomerProfileService {
	private profileService = inject(ProfileService);
	private accessLevelToStringPipe = inject(AccessLevelToStringPipe);

	async makeFormlyForExisting<T>(): Promise<FormlyStuff<T>> {
		const profile = this.profileService.profile();
		return {
			form: new FormGroup({}),
			model: profile as T,
			fields: this.makeFormlyFields(false),
		};
	}

	private makeFormlyFields(newAccount = true): FormlyFieldConfig[] {
		return [
			...(newAccount
				? [
						{
							fieldGroupClassName: 'flex flex-col md:flex-row items-center md:gap-5',
							fieldGroup: [
								{
									key: 'phoneNumber',
									type: FormlyCustomType.PhoneNumber,
									className: 'base-2/3',
									props: {
										required: true,
										minLength: 6,
									},
								},
								{
									key: 'permission',
									type: FormlyCustomType.SimpleSelect,
									className: 'base-1/3',
									props: {
										options: [
											{
												value: CompanyAccessLevel.OWNER,
												label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.OWNER),
											},
											{
												value: CompanyAccessLevel.ADVISOR,
												label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.ADVISOR),
											},
											{
												value: CompanyAccessLevel.CRUD,
												label: this.accessLevelToStringPipe.transform(CompanyAccessLevel.CRUD),
											},
											// {value:CompanyAccessLevel.Read, label:this.accessLevelToStringPipe.transform(CompanyAccessLevel.Read)},
										],
									},
								},
							],
						},
						{
							type: FormlyCustomType.Html,
							className: 'my-5',
							props: {
								text: 'profilePage.form.notRequired',
							},
						},
					]
				: [
						{
							key: phoneNumber,
							type: 'input',
							props: {
								type: 'tel',
								translate: true,
								label: 'profilePage.form.phoneNumber',
								attributes: { autocomplete: 'phone' },
								required: true,
							},
							expressions: {
								'props.disabled': 'true',
							},
						},
					]),
			{
				fieldGroupClassName: 'grid grid-cols-1 md:grid-cols-2 md:gap-5',
				fieldGroup: [
					{
						key: 'name',
						type: 'input',
						props: {
							translate: true,
							label: 'profilePage.form.name',
							attributes: { autocomplete: 'given-name' },
							required: !newAccount,
						},
					},
					{
						key: 'surname',
						type: 'input',
						props: {
							translate: true,
							label: 'profilePage.form.surname',
							attributes: { autocomplete: 'family-name' },
							required: !newAccount,
						},
					},
				],
			},
			{
				key: 'email',
				type: 'input',
				props: {
					type: 'email',
					translate: true,
					label: 'profilePage.form.email',
					attributes: { autocomplete: 'email' },
					required: !newAccount,
				},
				validators: {
					validation: ['email'],
				},
			},
		];
	}
}
