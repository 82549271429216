import { I18nService } from '@agdir/i18n/angular';
import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, first, isObservable, map, of } from 'rxjs';

type kindOfFn = () => string;

export class TranslateExtension implements FormlyExtension {
	constructor(private translate: I18nService) {}

	prePopulate(field: FormlyFieldConfig) {
		const props = field.props || {};
		const validation = field.validation || {};
		if (!props['translate'] || props['_translated']) {
			return;
		}

		props['_translated'] = true;
		field.expressions = {
			...(field.expressions || {}),
			'props.label': (props?.['label'] && this.forSomeReasonSelectTranslateRequiresScope(props['label'])) || of(null),
			'props.description': (props?.['description'] && this.forSomeReasonSelectTranslateRequiresScope(props['description'])) || of(null),
			'validation.messages.required':
				(validation?.['messages']?.['required'] &&
					this.forSomeReasonSelectTranslateRequiresScope(validation?.['messages']?.['required'] as string)) ||
				of(null),
			'validation.messages.invalid':
				(validation?.['messages']?.['invalid'] &&
					this.forSomeReasonSelectTranslateRequiresScope(validation?.['messages']?.['invalid'] as string)) ||
				of(null),
			'props.placeholder': props['placeholder'] ? this.forSomeReasonSelectTranslateRequiresScope(props['placeholder']) : of(null),
			'props.options': (props['options'] && this.translateSelectProps(props['options'])) || of([]),
		};
	}

	forSomeReasonSelectTranslateRequiresScope<T = any>(input: string | kindOfFn): Observable<T> {
		const parts = (typeof input === 'function' ? input() : input).split('.');
		const scope = parts.shift();
		return this.translate.selectTranslate(`${parts.join('.')}`, {}, scope).pipe(first());
	}

	private translateSelectProps(options: any[] | Observable<any> = []) {
		return isObservable(options)
			? options.pipe(
					map((obsOptions) =>
						this.translateSelectOptions(
							obsOptions as {
								label: string;
								value: any;
							}[],
						),
					),
			  )
			: this.translateSelectOptions(options as { label: string; value: any }[]);
	}

	private translateSelectOptions(options: { label: string; value: any }[] = []) {
		return of(
			options.map((opt) => ({
				...opt,
				label: this.translate.translate(String(opt.label)),
			})),
		);
	}
}
