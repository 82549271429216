import { ENVIRONMENT } from '@agdir/environment/angular';
import { Inject, Injectable } from '@angular/core';
import { Point } from '@turf/turf';
import mapboxgl, { Map } from 'mapbox-gl';
import { zoningColors } from './zoning-colors';
import { Environment } from '@agdir/environment/domain';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { DetectFieldResponse, NdviResponse } from '@agdir/vendors/digifarms/domain';

const DIGI_ZONING = {
	TABLE: 'demo_zoning_de_dreileben_zones',
	TILE_URL: 'https://vectors.digifarm.io/development/vectors/test/test/{z}/{x}/{y}.mvt',
};

type BBOX = string;

@Injectable()
export class DigifarmService {
	constructor(@Inject(ENVIRONMENT) private environment: Environment, private httpClient: HttpClient) {}

	static removeZoningLayers(map: mapboxgl.Map) {
		map.removeLayer(`zones7_line${DIGI_ZONING.TABLE}`);
		map.removeLayer(`zones7_${DIGI_ZONING.TABLE}`);
		map.removeSource(DIGI_ZONING.TABLE);
	}

	/**
	 * Proof of concept for the investor hunting round
	 * @param map
	 */
	static addZoningLayers(map: mapboxgl.Map) {
		const layerid = DIGI_ZONING.TABLE;
		const params = new URLSearchParams({
			table: layerid,
			limit: '1000',
			props: encodeURIComponent(
				JSON.stringify({
					smooth_raster: 5,
					smooth_vector: 2,
					number_of_zones: 3,
					interpolation_size: 1,
					interpolation_type: 'cubic',
					simplify_vector: '4.0',
					min_hole_area: 400,
				}),
			),
		}).toString();
		map.addSource(layerid, {
			type: 'vector',
			tiles: [`${DIGI_ZONING.TILE_URL}?${params}`],
		});
		map.addLayer({
			id: 'zones7_' + layerid,
			source: layerid,
			minzoom: 12,
			maxzoom: 24,
			type: 'fill',
			'source-layer': 'test',
			paint: {
				'fill-opacity': 0.5,
				'fill-color': ['match', ['get', 'zone'], ...zoningColors(3)],
			},
		});
		map.addLayer({
			id: 'zones7_line' + layerid,
			source: layerid,
			minzoom: 12,
			maxzoom: 24,
			type: 'line',
			'source-layer': 'test',
			paint: {
				'line-width': 0.5,
				'line-color': '#fff',
				'line-opacity': 0.5,
			},
		});
	}

	async canDelineateFields(map: mapboxgl.Map): Promise<boolean> {
		const bbox = this.getBBOXAsString(map);
		return firstValueFrom(this.httpClient.get<boolean>(`/fields/can-detect-field?bbox=${bbox}`));
	}

	async getDelineatedFields(map: mapboxgl.Map): Promise<DetectFieldResponse> {
		const bbox = this.getBBOXAsString(map);
		return firstValueFrom(this.httpClient.get<DetectFieldResponse>(`/fields/detect-field?bbox=${bbox}`));
	}

	async findFieldByCoords(point: Point): Promise<DetectFieldResponse> {
		return firstValueFrom(
			this.httpClient.get<DetectFieldResponse>(
				`/fields/detect-field?location=${point.coordinates[0].toFixed(4)},${point.coordinates[1].toFixed(4)}`,
			),
		);
	}

	async getNdvi(fieldId: string, from: Date, to: Date): Promise<NdviResponse> {
		const dates = `${from.getTime()},${to.getTime()}`;
		return firstValueFrom(this.httpClient.get<NdviResponse>(`/fields/ndvi?dates=${dates}&fieldId=${fieldId}`));
	}

	private getBBOXAsString(map: Map): BBOX {
		const boundsArray = map.getBounds().toArray();
		const minLng = boundsArray[0][0].toFixed(2);
		const minLat = boundsArray[0][1].toFixed(2);
		const maxLng = boundsArray[1][0].toFixed(2);
		const maxLat = boundsArray[1][1].toFixed(2);
		return `${minLng},${minLat},${maxLng},${maxLat}`;
	}
}
