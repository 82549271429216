import { AgdirFormsModule } from '@agdir/agdir-forms';
import { Location } from '@agdir/domain';
import { I18nModule } from '@agdir/i18n/angular';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, input, OnChanges, output, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, switchMap } from 'rxjs/operators';
import { GeocodingService } from '../services/geocoding.service';

@Component({
	standalone: true,
	selector: 'agdir-location-lookup',

	template: `
		<mat-form-field *ngIf="filterForCompanyName | async as filterControlForCompanyName">
			<input
				type="text"
				matInput
				[formControl]="$any(filterControlForCompanyName)"
				[matAutocomplete]="auto"
				[placeholder]="placeholder() | transloco"
				[autocomplete]="autocomplete()"
			/>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectLocation($event)">
				<mat-option *ngFor="let location of filteredLocations$ | async" [value]="location">
					{{ location.name }}
					<br />
					<span class="text-xs">{{ location.address?.formattedAddress }}</span>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	`,
	imports: [AgdirFormsModule, MatAutocompleteModule, AsyncPipe, I18nModule, NgFor, NgIf],
})
export class AutocompleteComponent implements OnChanges {
	searchControl = input<AbstractControl | null>();
	filterForCompanyName = new ReplaySubject<AbstractControl>();
	locationSelected = output<Location>();
	autocomplete = input('');
	placeholder = input('');
	private geocodingService = inject(GeocodingService);
	filteredLocations$ = this.filterForCompanyName.pipe(
		switchMap((c) => c.valueChanges),
		filter((v) => !!v),
		debounceTime(500),
		distinctUntilChanged(),
		switchMap((value: string) => this.geocodingService.fetchLocations(value)),
		shareReplay(1),
	);

	ngOnChanges(changes: SimpleChanges) {
		if (changes.searchControl) {
			this.filterForCompanyName.next(changes.searchControl.currentValue);
		}
	}

	async selectLocation($event: MatAutocompleteSelectedEvent) {
		const placeId = $event.option.value.meta.googlePlaceId;
		this.searchControl()?.setValue($event.option.value.name, { emitEvent: false });
		if (placeId) {
			const fullLocation = await firstValueFrom(this.geocodingService.getFullGoogleInfoOnPlace(placeId));
			if (fullLocation) {
				this.locationSelected.emit(fullLocation);
			}
		}
	}
}
