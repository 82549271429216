import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapboxDetectFieldsControlComponent } from './detect-fields/mapbox-detect-fields-control.component';
import { DigifarmService } from './digifarms.service';
import { SpinnerComponent } from '@agdir/ui/loaders';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@NgModule({
	declarations: [MapboxDetectFieldsControlComponent],
	exports: [MapboxDetectFieldsControlComponent],
	imports: [CommonModule, SpinnerComponent, AgdirIconComponent],
	providers: [DigifarmService, { provide: Window, useValue: window }],
})
export class DigifarmModule {}
