@if (!hideToolbar() && setMarkerOnClick()) {
	<div class="flex flex-row space-x-3 p-3 border-l border-t border-r">
		<button (click)="clearNewMarker()" *ngIf="newMarker()" mat-button type="button">
			<agdir-icon icon="close" />
			<span class="hidden md:inline ml-2" transloco="modules.locationPage.locationMap.clearPosText"></span>
		</button>
	</div>
}

<ng-container *ngIf="locationMap$ | async as mapSettings">
	<mgl-map
		(mapClick)="onMapClick($event)"
		(mapLoad)="onMapReady($event)"
		[attributionControl]="false"
		[fitBoundsOptions]="mapSettings.fitBoundsOptions"
		[fitBounds]="mapSettings.bounds"
		[maxZoom]="mapSettings.maxZoom"
		[minZoom]="mapSettings.minZoom"
		[style]="'mapbox://styles/mapbox/satellite-v9'"
		[zoom]="[mapSettings.zoom]"
		class="h-full w-full">
		@if (showLocateMeControl() && (myPosition$ | async); as myPosition) {
			<mgl-marker [lngLat]="[myPosition?.coordinates[0], myPosition?.coordinates[1]]">
				<span class="flex h-2 w-2">
					<span class="animate-ping absolute h-full w-full rounded-full bg-red-500 opacity-85"></span>
					<span class="rounded-full h-2 w-2 bg-[red]"></span>
				</span>
			</mgl-marker>
		}

		<ng-container *ngFor="let device of devices$ | async">
			@if (device?.geoPoint?.coordinates; as lngLat) {
				<mgl-marker [lngLat]="[lngLat[0] || 0, lngLat[1] || 0]">
					<!--					[label]="getTemperature(device)"-->
					<agdir-badge withDot [color]="device?.isConnected ? 'green' : 'red'" (click)="onMarkerClick(device)" />
				</mgl-marker>
			}
		</ng-container>
		<mgl-marker *ngIf="newMarker() && newMarker().coordinates" [lngLat]="[newMarker().coordinates[0], newMarker().coordinates[1]]">
			<agdir-badge icon="location_on" />
		</mgl-marker>

		<ng-container *ngIf="!editMode()">
			<ng-container *ngFor="let location of locations$ | async">
				<mgl-geojson-source [data]="location.geoJson" [id]="'polygon-' + location._id">
					<mgl-layer
						(layerClick)="onLocationClick($any($event.features), location)"
						[id]="'polygon-fill-' + location._id"
						[paint]="locationFillProps(location)"
						[source]="'polygon-' + location._id"
						type="fill"></mgl-layer>

					<mgl-layer
						[id]="'polygon-outline-' + location._id"
						[paint]="locationLineProps(location)"
						[source]="'polygon-' + location._id"
						type="line"></mgl-layer>

					<mgl-layer
						*ngIf="displayLocationNames()"
						[id]="'polygon-name-' + location._id"
						[layout]="{ 'text-field': location.name }"
						[source]="'polygon-' + location._id"
						type="symbol"></mgl-layer>
				</mgl-geojson-source>
			</ng-container>
		</ng-container>

		<mgl-image *ngIf="projectedPolygons()?.length" id="delete-icon" url="assets/images/trash.png"></mgl-image>

		<ng-container *ngFor="let projected of projectedPolygons()">
			<mgl-geojson-source [data]="projected.feature()" [id]="'child-polygon-' + projected.name">
				<mgl-layer
					[id]="'polygon-fill-' + projected.name"
					[paint]="projectedFillPaint(projected)"
					[source]="'child-polygon-' + projected.name"
					type="fill"></mgl-layer>

				<mgl-layer
					[id]="'polygon-outline-' + projected.name"
					[paint]="projectedOutlinePaint(projected)"
					[source]="'child-polygon-' + projected.name"
					type="line"></mgl-layer>

				<mgl-layer
					(layerClick)="projected.polygonClick.emit($event)"
					(layerTouchEnd)="projected.polygonClick.emit($event)"
					[id]="'polygon-name-' + projected.name"
					[layout]="projectedSymbolPaint(projected)"
					[source]="'child-polygon-' + projected.name"
					type="symbol"></mgl-layer>
			</mgl-geojson-source>
		</ng-container>
		<ng-container *transloco="let t">
			<mgl-control *ngIf="showGeocoderControl()" [placeholder]="t('core.qrCodePage.searchText')" mglGeocoder position="top-left"></mgl-control>
		</ng-container>

		<mgl-control *ngIf="showFullscreen()" agdirMapFullScreen position="top-right"></mgl-control>

		<mgl-control (locationSplitted)="onLocationSplitted($event)" *ngIf="showSplitMergeTools()" agdirSplit position="top-right"></mgl-control>

		<ng-container *ngIf="locations$ | async as locations">
			<mgl-control
				(locationMerged)="onLocationMerged($event)"
				*ngIf="showSplitMergeTools() && locations.length > 1"
				agdirMerge
				position="top-right"></mgl-control>
		</ng-container>
		<mgl-control
			(locationDrawn)="onMapBoxDraw($event)"
			(locationUpdated)="onMapBoxUpdated($event)"
			*ngIf="showDrawingTools() || editMode()"
			[editMode]="editMode()"
			agdirDraw
			position="top-right"></mgl-control>

		<mgl-control (geolocate)="onGeoLocate($event)" *ngIf="showLocateMeControl()" agdirGeolocate position="top-right"></mgl-control>
		<mgl-control
			(detecting)="loading.emit($event)"
			(fieldsDetected)="fieldsDetected.emit($event)"
			*ngIf="showDetectFieldsControl()"
			[minZoom]="14"
			agdirDetectFields
			position="top-right"></mgl-control>

		<mgl-control (showZones)="showZones($event)" *ngIf="showZoningLayers()" agdirDigiZoning position="top-right"></mgl-control>

		<ng-container *ngIf="hasAnyZone">
			<mgl-control agdirCheckboxControl label="Show zones" position="top-right" (showZone)="locationZoneFilterChange($event)"></mgl-control>
			<!-- <mgl-control
				agdirCheckboxControl
				label="Show main fields"
				position="top-right"
				(showZone)="mainLocationFilterChange($event)"></mgl-control> -->
		</ng-container>

		<ng-content></ng-content>
	</mgl-map>
</ng-container>
