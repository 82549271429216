import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { AgdirIconComponent } from '@agdir/agdir-icons';

@Component({
	standalone: true,
	selector: 'agdir-map-fullscreen-control',
	template: `
		<button type="button" matRipple (click)="click()" class="flex flex-col items-center justify-center w-full h-auto py-1 px-0.5">
			<agdir-icon class="w-5" *ngIf="fullscreen" icon="fullscreen_exit" />
			<agdir-icon class="w-5" *ngIf="!fullscreen" icon="fullscreen" />
			<span>Fullscreen</span>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, MatRippleModule, AgdirIconComponent],
})
export class MapFullscreenComponent {
	fullscreen = false;

	constructor(private cd: ChangeDetectorRef) {}

	click() {
		this.fullscreen = !this.fullscreen;
		if (this.fullscreen) {
			this.enterFullscreen();
		} else {
			this.exitFullscreen();
		}
		this.cd.markForCheck();
		this.cd.detectChanges();
	}

	enterFullscreen() {
		window.document.querySelector('mgl-map')?.classList.add('fullscreen');
		window.dispatchEvent(new Event('resize'));
	}

	exitFullscreen() {
		window.document.querySelector('mgl-map')?.classList.remove('fullscreen');
		window.dispatchEvent(new Event('resize'));
	}
}
